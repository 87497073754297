import React from "react";
import { Redirect } from "react-router-dom";
// import { lazy } from "react/cjs/react.production.min";

// Form
import Formulario from "../pages/Formulario";

// // Extra Pages
// import Pages404 from "../pages/ExtraPages/Pages404";
// import Pages500 from "../pages/ExtraPages/Pages500";
// import PagesMaintenance from "../pages/ExtraPages/PagesMaintenance";

import Anuncios from "../pages/Anuncios";
import AnuncioDetalhe from "../pages/Anuncios/view/anuncioDetalhe";
import Recados from "../pages/Recados";
import RecadoDetalhes from "../pages/Recados/view/recadoDetalhes";
import Regulamento from "../pages/Regulamento";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade";

// const Recado = lazy(() => import('../pages/Recado'));

const routes = [
  { path: "/animais", component: Anuncios },
  { path: "/propriedades", component: Anuncios },
  { path: "/maquinarios", component: Anuncios },
  { path: "/imovelUrbano", component: Anuncios },

  // Form
  { path: "/encomendar", component: Formulario },
  { path: "/vender", component: Formulario },

  { path: "/recados", component: Recados },
  { path: "/recado/:id", exact: true, component: RecadoDetalhes },

  { path: "/:tipo/:id", component: AnuncioDetalhe },

  { path: "/regulamento", component: Regulamento },
  { path: "/politica", component: PoliticaPrivacidade },

  // Extra Pages
  // { path: "/codigo-404", component: Pages404 },
  // { path: "/codigo-500", component: Pages500 },
  // { path: "/pages-Maintenance", component: PagesMaintenance },

  { path: "/", exact: true, component: () => <Redirect to="/animais" /> }
];

export { routes };
