import {
    FIND_REGULAMENTO_PENDING,
    FIND_REGULAMENTO_SUCCESS,
    FIND_REGULAMENTO_FAILURE,
} from './actionTypes';


const initialState = { loading: false }

function regulamentoReducer(state = initialState, action) {

    switch (action.type) {
        case FIND_REGULAMENTO_PENDING:
            return {
                ...state,
                loading: true,
            }
         // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_REGULAMENTO_SUCCESS:
            return {
                ...state,
                regulamento: action.regulamento,
                loading: false
            }
        case FIND_REGULAMENTO_FAILURE:
            return {
                ...state,
                regulamento: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { regulamentoReducer };