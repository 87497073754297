import React from 'react';

import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

const NUMBER_ROWS = 15;

class TableView extends React.Component {

    constructor(props) {
        super(props);

        this.state = { rows: NUMBER_ROWS, layout: 'grid', loading: true };
    }

    async componentDidMount() {
        await this.onPage({ rows: NUMBER_ROWS, page: 0 });
    }

    onPage = async (event) => {
        await this.paginate({ rows: event.rows, pageCount: event.page === undefined ? event.originalEvent.page : event.page });
        this.setState({ rows: event.originalEvent === undefined ? event.rows : event.originalEvent.rows, loading: false });
    }

    paginate = async ({ rows, pageCount, args }) => {
        var size = 'size=' + rows;
        var page = 'page=' + (pageCount);

        let total = this.props.tipo + '?' + size + '&' + page + (this.props.where != null && this.props.where != null ? `&${this.props.where}` : '');
        if (args) {
            total = total.concat(args)
        }

        await this.props.list(total);
    }

    renderHeader() {
        let onOptionChange = (e) => {
            this.setState({ loading: true }, () => {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        layout: e.value
                    });
                }, 1000);
            });
        };

        return (
            <div style={{ textAlign: 'left' }}>
                <DataViewLayoutOptions layout={this.state.layout} onChange={onOptionChange} />
            </div>
        );
    }

    render() {
        // const header = this.renderHeader();
        const refDt = React.createRef();
        const { reducer, keyInput, itemGrid } = this.props;

        return (
            <>
                {reducer.table &&
                    <div className="dataview-demo">
                        <div className="card">
                            <DataView
                                key={'id_' + keyInput}
                                ref={refDt}
                                value={reducer.table.content}
                                layout={this.state.layout}
                                // header={header}
                                itemTemplate={itemGrid}
                                lazy={true}
                                paginator={true}
                                paginatorPosition={'both'}
                                rows={this.state.rows}
                                totalRecords={reducer.table.totalElements}
                                first={((reducer.table.pageable.pageNumber) * NUMBER_ROWS) + 1}
                                onPage={this.onPage}
                                loading={this.state.loading}
                                emptyMessage="Nenhum registro encontrado"
                            />
                        </div>
                    </div>
                }
            </>
        );
    }
}

TableView.defaultProps = {
    keyInput: 'tableViewSearch',
    itemGrid: (item) => {
        if (item === null) {
            return <React.Fragment />;
        }
    }
};

export { TableView };