import { takeLatest, put, call } from 'redux-saga/effects';

import { findSubGrupoAnuncio } from '../../anuncios/service';
import {
  FIND_SUBGRUPO_ANUNCIO,
  FIND_SUBGRUPO_ANUNCIO_PENDING,
  FIND_SUBGRUPO_ANUNCIO_SUCCESS,
  FIND_SUBGRUPO_ANUNCIO_FAILURE,
} from '../../anuncios/actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaFindSubGrupoAnuncio(action) {
  yield put({ type: FIND_SUBGRUPO_ANUNCIO_PENDING, id: action.id})

  try {

    const subGrupo = yield call(findSubGrupoAnuncio, action.args, action.id);

    yield put({ type: FIND_SUBGRUPO_ANUNCIO_SUCCESS, subGrupo: subGrupo })

  } catch (error) {
    yield put({ type: FIND_SUBGRUPO_ANUNCIO_FAILURE })

    AlertError('Falha ao carregar os subgrupos. Tente novamente...');
  }
}

export default function* watchFindSubGrupoAnuncio() {
  yield takeLatest(FIND_SUBGRUPO_ANUNCIO, sagaFindSubGrupoAnuncio)
}
