import React from "react";
import googlePlayBanner from '../../assets/images/google-play-badge.svg';
import appleStoreBanner from '../../assets/images/app-store-badge.svg';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" id="footer-gb">
        <div className="container-fluid">
          <div>
            <h4>Baixe agora o Gado Bom para o seu smartphone</h4>
            <h5>Estamos nas principais lojas de aplicativos!</h5>
          </div>

          <div className="row dnldBadges">
            <a href={"https://play.google.com/store/apps/details?id=br.com.appgadobom"} target="_blank" rel="noreferrer">
              <img className="badge-item" src={googlePlayBanner} alt="" height="60"/>
            </a>
            <a href={"https://itunes.apple.com/us/app/gado-bom/id1373828172?ls=1&mt=8"} target="_blank" rel="noreferrer">
              <img className="badge-item" src={appleStoreBanner} alt="" height="60" />
            </a>
          </div>

          <hr/>

          <div className="row">
            <div className="col-12">
              © {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
