export const WS_URL_SITE = 'https://appgadobom.com.br';

export const WS_URL_WS_CENTRAL = 'https://appgadobom.com.br/GadoBom-ws-central-0.1';
export const WS_URL_WS_CENTRAL_BANCO = 'https://appgadobom.com.br/GadoBom-ws-banco-0.1';
// export const WS_URL_WS_CENTRAL = 'http://localhost:8080/GadoBom-ws-central-0.1';
// export const WS_URL_WS_CENTRAL_BANCO = 'http://localhost:8080/GadoBom-ws-banco-0.1';

export const WS_GET_CONFIG = `${WS_URL_WS_CENTRAL}/config/1`;
export const WS_GET_PROPAGANDA = `${WS_URL_WS_CENTRAL}/propaganda/app`;

export const GA_TRACKING_ID = `G-1PF6J388L1`;


export const MIXPANEL_TOKEN = 'a3565bd5df7760b7f747ada8fcdedf59'; // Prod
// export const MIXPANEL_TOKEN = '71296fbc4513c49fa41dc2f0ca742041'; // Homolog