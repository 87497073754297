import { takeLatest, put, call } from 'redux-saga/effects';

import { listRecados } from '../service';
import {
  TABLE_RECADOS,
  TABLE_RECADOS_PENDING,
  TABLE_RECADOS_SUCCESS,
  TABLE_RECADOS_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaListRecados(action) {
  yield put({ type: TABLE_RECADOS_PENDING })

  try {
    const table = yield call(listRecados, action.args)

    yield put({ type: TABLE_RECADOS_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_RECADOS_FAILURE })

    AlertError('Falha ao carregar os recados. Tente novamente...');
  }
}

export default function* watchListRecados() {
  yield takeLatest(TABLE_RECADOS, sagaListRecados)
}