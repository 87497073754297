import { takeLatest, put, call } from 'redux-saga/effects';

import { listAgencias } from '../service';
import {
    LIST_AGENCIAS,
    LIST_AGENCIAS_PENDING,
    LIST_AGENCIAS_SUCCESS,
    LIST_AGENCIAS_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaListAgencias(action) {
  yield put({ type: LIST_AGENCIAS_PENDING })

  try {
    const agencias = yield call(listAgencias, action.args)

    yield put({ type: LIST_AGENCIAS_SUCCESS, agencias: agencias })
  } catch (error) {
    yield put({ type: LIST_AGENCIAS_FAILURE })

    AlertError('Falha ao carregar as Agencias. Tente novamente...');
  }
}

export default function* watchListAgencias() {
  yield takeLatest(LIST_AGENCIAS, sagaListAgencias)
}