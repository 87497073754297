import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    // Row
} from 'reactstrap';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Delete } from '@components/form'

// const cols = [
//     { field: 'status', header: 'Code' },
//     { field: 'classificacao', header: 'Name' },
//     { field: 'descricao', header: 'Category' },
//     { field: 'tipoConta', header: 'Quantity' }
// ];

class Table extends React.Component {

    constructor(props) {
        super(props);

        this.state = { rows: 100 };
    }

    async componentDidMount() {
        if (this.props.auto) {
            await this.onPage({ rows: 100, page: 0 });
        }

        await this.props.init();
    }

    onPage = async (event) => {
        await this.paginate({ rows: event.rows, pageCount: event.page });
        this.setState({ rows: event.rows });
    }

    paginate = async ({ rows, pageCount, args }) => {
        var size = 'size=' + rows;
        var page = 'page=' + (pageCount);

        let total = '?' + size + '&' + page + (this.props.where != null && this.props.where != null ? `&${this.props.where}` : '');

        if (args) {
            total = total.concat(args)
        }

        await this.props.list(total);
    }

    render() {
        const refDt = React.createRef();
        const { reducer, paginator, lazy, showBtnExport, indexFieldMsgDelete, keyInput } = this.props;
        const { editLink, onRemove, onEdit } = this.props;

        // const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

        // const exportPdf = () => {
        //     import('jspdf').then(jsPDF => {
        //         import('jspdf-autotable').then(() => {
        //             const doc = new jsPDF.default(0, 0);
        //             doc.
        //             doc.autoTable(exportColumns, reducer.table.content);
        //             doc.save('products.pdf');
        //         })
        //     })
        // }

        const paginatorLeft =
            showBtnExport ? ((reducer.table === undefined || reducer.table === null || reducer.table.empty) ? <React.Fragment /> : 
            
            // <Row>
                <Button type="button" className="btn btn-dark" onClick={() => { refDt.current.exportCSV(); }}>
                <i className='fas fa-file-export' /> Exportar (.csv)
            </Button>
            // <Button type="button" className="btn btn-dark" onClick={() => { exportPdf(); }}>
            //     <i className='fas fa-file-export' /> PDF
            // </Button>
            // </Row>
            
            ) : <div></div>;

        return (
            <>
                {reducer.table &&
                    <DataTable
                        key={'id_' + keyInput} ref={refDt} value={reducer.table.content} paginator={paginator} lazy={lazy} paginatorLeft={paginatorLeft}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Total de registros: {totalRecords}" rows={this.state.rows} rowsPerPageOptions={[50, 100, 500]}
                        className='table table-striped my-4 w-100 p-datatable-sm' totalRecords={reducer.table.totalElements} autoLayout={true}
                        first={reducer.table.number} onPage={this.onPage} emptyMessage="Nenhum registro encontrado" csvSeparator=';'
                    >
                        {this.props.children}

                        {
                            <Column style={{ textAlign: 'center', width: this.props.actionWidth, display: this.props.renderActions ? undefined : 'none' }}
                                body={(rowData, column) => {
                                    return (
                                        <div>

                                            {this.props.renderEdit &&
                                                <Link to={`${editLink}${rowData.id}`}>
                                                    <Button outline color='white' className='btn btn-warning btn-sm'
                                                        onClick={() => onEdit(rowData.id)}
                                                        style={{ marginRight: '5px' }}>
                                                        <i className='fas fa-pencil-alt' />
                                                    </Button>
                                                </Link>
                                            }

                                            {this.props.renderDelete &&
                                                <Delete registro={rowData} remove={onRemove}
                                                    removed={reducer.removed} indexFieldMsgDelete={indexFieldMsgDelete} />
                                            }
                                        </div>
                                    );
                                }} />
                        }
                    </DataTable>
                }
            </>
        )

    }
}

Table.defaultProps = {
    keyInput: 'tableSearch',
    renderActions: true,
    paginator: true,
    lazy: true,
    renderEdit: true,
    renderDelete: true,
    actionWidth: '15%',
    auto: true,
    showBtnExport: true,
    indexFieldMsgDelete: 0,
    onPage: () => { },
    onRemove: () => { },
    onEdit: () => { },
    init: () => { },
};

export { Table };