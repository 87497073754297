export const LIST_BANCOS = 'LIST_BANCOS'
export const LIST_BANCOS_PENDING = 'LIST_BANCOS_PENDING'
export const LIST_BANCOS_SUCCESS = 'LIST_BANCOS_SUCCESS'
export const LIST_BANCOS_FAILURE = 'LIST_BANCOS_FAILURE'

export const LIST_AGENCIAS = 'LIST_AGENCIAS'
export const LIST_AGENCIAS_PENDING = 'LIST_AGENCIAS_PENDING'
export const LIST_AGENCIAS_SUCCESS = 'LIST_AGENCIAS_SUCCESS'
export const LIST_AGENCIAS_FAILURE = 'LIST_AGENCIAS_FAILURE'

export const LIST_PROGRAMAS = 'LIST_PROGRAMAS'
export const LIST_PROGRAMAS_PENDING = 'LIST_PROGRAMAS_PENDING'
export const LIST_PROGRAMAS_SUCCESS = 'LIST_PROGRAMAS_SUCCESS'
export const LIST_PROGRAMAS_FAILURE = 'LIST_PROGRAMAS_FAILURE'