import React, { Component } from "react";
import { Col, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { InputText, InputTextArea } from "@components/form";
import { AlertSuccess, AlertError } from "@components/common/AlertToast";
import { WS_URL_WS_CENTRAL } from "@commons/Const";
import { trackEvent } from "mixpanel";

import ReactGA from 'react-ga';

class ModalProposta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            btnText: "Enviar"
        };
    }

    changeBtnText = btnText => {
        this.setState({ btnText });
    };

    formSubmit(values) {
        this.changeBtnText("Enviando...");

        ReactGA.event({
            category: 'Proposta',
            action: 'Envio de proposta'
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: `{
                "nome": "${values.nome}",
                "telefone": "${values.telefone}",
                "email": "${values.email}",
                "proposta": "${values.proposta}",
                "cidade": "${values.cidade}",
                "${this.props.tipoAnuncio}": {
                    "id": ${this.props.item.id}
                }
            }`
        };
        fetch(`${WS_URL_WS_CENTRAL}/proposta`, requestOptions)
            .then(response => response.json())
            .then(() => {
                values.nome = "";
                values.telefone = "";
                values.email = "";
                values.cidade = "";
                values.proposta = "";
                this.setState({ btnText: "Enviar" });
                AlertSuccess('Proposta enviada com sucesso! Aguarde que em breve entraremos em contato.');

                trackEvent(`[Web] - Proposta [${this.props.tipoAnuncio}] enviada com sucesso: Lote: ${this.props.item.lote} - ${this.props.item.titulo}`);
            }
            ).catch(() => {
                this.setState({ btnText: "Enviar" });
                AlertError('Sua proposta não foi enviada. Algo inesperado aconteceu. Tente novamente ou entre em contato via nosso canal de WhatsApp.');
            });
        this.props.handleClose();
    }

    render() {
        const { item } = this.props;

        return (

            <React.Fragment>
                {item === undefined || item === null
                    ? <React.Fragment />
                    :
                    <Modal isOpen={this.props.showProposta} size="xl" style={{ maxWidth: '1600px', width: '90%' }}>
                        <ModalHeader style={{ display: "flex", justifyContent: "center" }}>
                            <Row style={{ justifyContent: "center" }}>
                                <p className="">Faça sua proposta ou ligue nestes contatos:&nbsp;</p>
                                <p className="">(55) 3422-8558&nbsp;|&nbsp;</p>
                                <p className="">(55) 9 9964-1149&nbsp;|&nbsp;</p>
                                <p className="">(55) 9 9626-3179</p>
                            </Row>
                        </ModalHeader>
                        <Formik
                            enableReinitialize
                            initialValues={{ nome: '', telefone: '', email: '', cidade: '', proposta: '', recaptcha: '' }}
                            validationSchema={
                                Yup.object().shape({
                                    nome: Yup.string()
                                        .min(2, 'Muito curto!')
                                        .max(50, 'Campo deve ter menos do que 50 caracteres!')
                                        .required('Campo Obrigatório'),
                                    telefone: Yup.string()
                                        .min(10, 'Muito curto!')
                                        .max(20, 'Campo deve ter menos do que 20 caracteres!')
                                        .required('Campo Obrigatório'),
                                    email: Yup.string()
                                        .email('E-mail Inválido'),
                                    cidade: Yup.string()
                                        .min(2, 'Muito curto!')
                                        .max(50, 'Campo deve ter menos do que 50 caracteres!')
                                        .required('Campo Obrigatório'),
                                    proposta: Yup.string()
                                        .min(2, 'Muito curto!')
                                        .max(4000, 'Campo deve ter menos do que 4000 caracteres!')
                                        .required('Campo Obrigatório'),
                                    recaptcha: Yup.string(),
                                })
                            }
                            onSubmit={(values) => this.formSubmit(values)}
                        >
                            {({errors, touched, setFieldValue}) => (
                                <Form autoComplete="off">
                                    <ModalBody>
                                        <div style={{ marginBottom: "15px", backgroundColor: '#012F20', padding: "10px" }}>
                                            <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'white', fontSize: "20px" }}>Lote: {item.lote}</h2>
                                            <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'white', fontSize: "20px" }}>{item.titulo}</h2>
                                        </div>
                                        <div className="">
                                            <InputText label="Nome" name='nome' placeholder='Informe seu Nome' />
                                            <InputText label="Telefone" name='telefone' placeholder='Informe seu Telefone' />
                                            <InputText label="E-mail (Opcional)" name='email' placeholder='Informe seu E-mail' />
                                            <InputText label="Cidade/Estado" name='cidade' placeholder='Informe sua Cidade e Estado' />
                                            <InputTextArea label="Proposta" name='proposta' placeholder='Informe sua Proposta' />
                                            <Col className="text-right" style={{ display: "flex", justifyContent: "space-between" }}>
                                            </Col>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button className="btn btn-light w-md waves-effect waves-light" onClick={this.props.handleClose}>
                                            <i className="fas fa-times-circle mr-2" />Fechar
                                        </button>
                                        <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                            <i className="fas fa-paper-plane mr-2" />{this.state.btnText}
                                        </button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default ModalProposta;