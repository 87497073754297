import { takeLatest, put, call } from 'redux-saga/effects';

import { findAnuncio } from '../service';
import {
  FIND_ANUNCIO,
  FIND_ANUNCIO_PENDING,
  FIND_ANUNCIO_SUCCESS,
  FIND_ANUNCIO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaFindAnuncio(action) {
  yield put({ type: FIND_ANUNCIO_PENDING, id: action.id})

  try {

    const anuncio = yield call(findAnuncio, action.args, action.id);

    yield put({ type: FIND_ANUNCIO_SUCCESS, anuncio: anuncio })

  } catch (error) {
    yield put({ type: FIND_ANUNCIO_FAILURE })

    AlertError('Falha ao carregar o anuncio. Tente novamente...');
  }
}

export default function* watchFindAnuncio() {
  yield takeLatest(FIND_ANUNCIO, sagaFindAnuncio)
}
