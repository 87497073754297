import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import HttpsRedirect from 'react-https-redirect';

// Import Routes
import { routes } from "./routes/";
import AppRoute from "./routes/route";
import { history } from './routes/history';

// layouts
import HorizontalLayout from "./@components/layout";

// Import scss
import "./assets/scss/theme.scss";

import 'react-toastify/dist/ReactToastify.css';

import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import ReactGA from 'react-ga';

function initializeReactGA() {
  ReactGA.initialize('UA-000000-01');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    initializeReactGA();
    toast.configure();
  }

  render() {
    const Layout = HorizontalLayout;
    
    return (
      <React.Fragment>
        <Router history={history}>
          <HttpsRedirect>
            <Switch>
              {routes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}
            </Switch>
          </HttpsRedirect>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
