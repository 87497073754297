import {
    GET_CONFIG_PENDING,
    GET_CONFIG_SUCCESS,
    GET_CONFIG_FAILURE,
} from './actionTypes';


const initialState = { loading: false }

function configReducer(state = initialState, action) {

    switch (action.type) {
        case GET_CONFIG_PENDING:
            return {
                ...state,
                loading: true,
            }
         // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case GET_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.config,
                loading: false
            }
        case GET_CONFIG_FAILURE:
            return {
                ...state,
                config: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { configReducer };