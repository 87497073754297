import React from "react";
import { withRouter, Link } from "react-router-dom";
// import { Row, Col, Card, CardBody, } from "reactstrap";

import { connect } from 'react-redux';

import { PageLoaderScreen } from "../../@components/common/PageLoaderScreen";
import { TableView } from "@components/form";
import { TABLE_RECADOS } from "@handler/common/recados";

import CardRecado from "./components/CardRecado";
import "primeflex/primeflex.css";
import { trackEvent } from "mixpanel";

class Recados extends React.Component {

  // ///////////////////////////////////////////////////////////////////////////////
  componentDidMount() {
      trackEvent('[Web] - Acessou a Página de Notícias');
  }
  // ///////////////////////////////////////////////////////////////////////////////

  render() {

    const { recadoReducer } = this.props;

    const itemGrid = (item, layout) => {
      if (!item)
        return;
      // if (layout === 'list')
      //   return <CardRecado item={item} /> // criar uma view para modo lista
      // else if (layout === 'grid')
      return <CardRecado item={item} /> // criar uma view para modo grid
    }

    return (
      <React.Fragment>
        <PageLoaderScreen loading={recadoReducer.loading} />

        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-title-box">
                <h4 className="font-size-18">Notícias</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Gado Bom</Link>
                  </li>
                  <li className="breadcrumb-item active">Notícias</li>
                </ol>
              </div>
            </div>
          </div>

          <TableView
            reducer={recadoReducer}
            list={this.props.listTableRecados}
            tipo={"recado"}
            where={`ativo=true`}
            itemGrid={itemGrid}
          />
        </div>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state) {
  const { recadoReducer } = state.entities;
  return { recadoReducer };
};

function mapDispatchToProps(dispatch) {
  return {
    listTableRecados: (args) => dispatch({ type: TABLE_RECADOS, args }),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Recados))