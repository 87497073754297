import { takeLatest, put, call } from 'redux-saga/effects';

import { getConfig } from '../service';
import {
    GET_CONFIG,
    GET_CONFIG_PENDING,
    GET_CONFIG_SUCCESS,
    GET_CONFIG_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaGetConfig() {
  yield put({ type: GET_CONFIG_PENDING })

  try {

    const config = yield call(getConfig);

    yield put({ type: GET_CONFIG_SUCCESS, config: config })
  } catch (error) {
    yield put({ type: GET_CONFIG_FAILURE })

    AlertError('Falha ao carregar as configurações, Tente novamente...');
  }
}

export default function* watchGetConfig() {
  yield takeLatest(GET_CONFIG, sagaGetConfig)
}
