import { takeLatest, put, call } from 'redux-saga/effects';

import { listAnuncios } from '../service';
import {
  TABLE_ANUNCIOS,
  TABLE_ANUNCIOS_PENDING,
  TABLE_ANUNCIOS_SUCCESS,
  TABLE_ANUNCIOS_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaListAnuncios(action) {
  yield put({ type: TABLE_ANUNCIOS_PENDING })

  try {
    const table = yield call(listAnuncios, action.args)

    yield put({ type: TABLE_ANUNCIOS_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_ANUNCIOS_FAILURE })

    AlertError('Falha ao carregar os anuncios. Tente novamente...');
  }
}

export default function* watchListAnuncios() {
  yield takeLatest(TABLE_ANUNCIOS, sagaListAnuncios)
}