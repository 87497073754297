import { takeLatest, put, call } from 'redux-saga/effects';

import { listProgramas } from '../service';
import {
    LIST_PROGRAMAS,
    LIST_PROGRAMAS_PENDING,
    LIST_PROGRAMAS_SUCCESS,
    LIST_PROGRAMAS_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaListProgramas(action) {
  yield put({ type: LIST_PROGRAMAS_PENDING })

  try {
    const programas = yield call(listProgramas, action.args)

    yield put({ type: LIST_PROGRAMAS_SUCCESS, programas: programas })
  } catch (error) {
    yield put({ type: LIST_PROGRAMAS_FAILURE })

    AlertError('Falha ao carregar os Programas. Tente novamente...');
  }
}

export default function* watchListProgramas() {
  yield takeLatest(LIST_PROGRAMAS, sagaListProgramas)
}