import { takeLatest, put, call } from 'redux-saga/effects';

import { findPropagandas } from '../service';
import {
    FIND_PROPAGANDAS,
    FIND_PROPAGANDAS_PENDING,
    FIND_PROPAGANDAS_SUCCESS,
    FIND_PROPAGANDAS_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaFindPropaganda(action) {
  yield put({ type: FIND_PROPAGANDAS_PENDING })

  try {

    const propagandas = yield call(findPropagandas, action.args);

    yield put({ type: FIND_PROPAGANDAS_SUCCESS, propagandas: propagandas })
  } catch (error) {
    yield put({ type: FIND_PROPAGANDAS_FAILURE })

    AlertError('Falha ao carregar as propagandas Tente novamente...');
  }
}

export default function* watchFindPropagandas() {
  yield takeLatest(FIND_PROPAGANDAS, sagaFindPropaganda)
}
