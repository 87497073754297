import watchFindRecado from './saga/find';
import watchListRecados from './saga/listTable';

export const recadoSaga = [
    watchFindRecado(),
    watchListRecados(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
