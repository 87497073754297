import React from "react";
import { withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import { FIND_PROPAGANDAS } from "@handler/common/propagandas";
import { PageLoaderScreen } from "../common/PageLoaderScreen";
import bannerAgrare from "../../assets/images/AnuncioAgrare.png";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { WS_URL_SITE } from '@commons/Const';
import './style.css'

class Propagandas extends React.Component {

    constructor(props) {
        super(props);
    
        // this.state = {}
    
        this.renderCarrossel = this.renderCarrossel.bind(this);
    }

    componentDidMount(){
        this.props.findPropagandas(this.props.urlPropaganda);
    }

    renderCarrossel(propaganda){

        const items = propaganda.map((item, index) => {
            const style = { height: 200 + index * 10};
            return (
                <a href={item.url} target="_blank">
                  <img
                      key={item.id}
                      src={`${WS_URL_SITE}${item.banner}`}
                      className="framePropaganda"
                      alt={item.descricao}
                  />
                </a>
            )
        });

        const propagandas = [
          ...items
        ];

        return <AliceCarousel
            autoPlay={true}
            autoPlayControls={false}
            autoPlayInterval={2000}  
            infinite
            disableDotsControls
            disableButtonsControls
            items={propagandas}
        />
    }


    render() {

        const { propagandaReducer } = this.props;

        return (
            <React.Fragment>
                <PageLoaderScreen loading={propagandaReducer.loading} />
                {propagandaReducer === undefined || propagandaReducer === null ||
                  propagandaReducer.propagandas === undefined || propagandaReducer.propagandas === null
                  ? '' : 
                  this.renderCarrossel(propagandaReducer.propagandas)
                  }
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
  const { propagandaReducer } = state.entities;
  return { propagandaReducer };
};

function mapDispatchToProps(dispatch) {
  return {
    findPropagandas: (args) => dispatch({ type: FIND_PROPAGANDAS, args }),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Propagandas));