import React, { Component } from 'react'; 

import { WS_URL_SITE } from '@commons/Const';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LightBox from '@components/common/LightBox';
import "react-image-lightbox/style.css";
import "./slide.css"

import logolightImg from "../../../assets/images/logo-light.png";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

class Slidewithcaption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            photoIndex: 0,
            isGalleryZoom: false,
            slideLenght: 0,
        };

        this.showZoom = this.showZoom.bind(this);
        this.hideZoom = this.hideZoom.bind(this);
        this.renderCarrossel = this.renderCarrossel.bind(this);
        this.renderImage = this.renderImage.bind(this);
        this.renderVideo = this.renderVideo.bind(this);

        this.next = this.next.bind(this);
        this.previous = this. previous.bind(this);
    }

    showZoom = () => {
        this.setState({ isGalleryZoom: true });
    };

    hideZoom = () => {
        this.setState({ isGalleryZoom: false });
    };
    
    next= (items) => {
        // if (this.animating) return
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }
    
    previous= (items) => {
        // if (this.animating) return
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex })
    }

    renderCarrossel(galeria, videos){
        
        const { activeIndex } = this.state;
        const images = this.renderImage(galeria);
        const slideVideos = this.renderVideo(videos);
        const slides = [...images, ...slideVideos];

        return <React.Fragment>
            <AliceCarousel
                activeIndex={activeIndex}
                mouseTracking
                disableDotsControls
                disableButtonsControls
                autoHeight
                items={slides}
            />
             <div className={"divControles mt-1 mb-2"}>
                <button className={"btn btn-light"} onClick={() => this.previous(slides)}>Anterior</button>
                <nav className={"numberSlide"}>{`${activeIndex+1} | ${slides.length}`}</nav>
                <button className={"btn btn-light"} onClick={() => this.next(slides)}>Próximo</button>
            </div>
        </React.Fragment>
    }

    renderImage(galeria) {
        const { photoIndex } = this.state;
        return galeria.map((item, index) => {
            return (
                <div className="containerFrame">
                    <img
                        src={`${WS_URL_SITE}${item.foto.path}`}
                        className="slideFrame"
                        alt={"Imagem"}
                        onClick={() =>
                            this.setState({ isGalleryZoom: true, photoIndex: index })
                        }
                    />
                    <img src={logolightImg} className="imageCaption" alt={`Logo Gado-Bom`}/>
                </div>
            );
        });
    }

    renderVideo(videos) {
        return videos.map((item, index) => {
            return (
                <iframe
                    allow="fullscreen;"
                    allowFullScreen="allowFullScreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    frameBorder="0"
                    title="Video"
                    className="slideFrame"
                    src={`https://www.youtube.com/embed/${item}?rel=0&showinfo=0&allowFullScreen=true`}
                ></iframe>
            );
        });
    }

    render() {
        const { photoIndex, isGalleryZoom } = this.state;
        const galeria = this.props.galeria;
        const videos = this.props.videos;

        return (
            <React.Fragment>
                {this.state.isGalleryZoom ? (
                        // <LightBox 
                        //     isOpen={isGalleryZoom} 
                        //     activeIndex={photoIndex} 
                        //     imagens={galeria} 
                        //     videos={videos}
                        //     handleClose={this.hideZoom}
                        //     next={this.next}
                        //     previous={this.previous}
                        // />
                    <Modal isOpen={isGalleryZoom} size="xl" style={{ maxWidth: '1600px', width: '90%', height: '90vh'}}>
                        <ModalHeader style={{ padding: "0", justifyContent: "flex-end"}}>
                            <button className="btn btn-light" onClick={this.hideZoom}>
                                {/* <i className="fas fa-times-circle mr-2" />Fechar */}
                                <i className="fas fa-times-circle" />
                            </button>
                        </ModalHeader>
                        <ModalBody className={"zoom"} style={{paddingBottom: "0"}}>
                            {this.renderCarrossel(galeria, videos)}
                        </ModalBody>
                    </Modal>
                ) : null}
                {this.renderCarrossel(galeria, videos)}
            </React.Fragment>
        );
    }
}

export default Slidewithcaption;