import {
    TABLE_ANUNCIOS_PENDING,
    TABLE_ANUNCIOS_SUCCESS,
    TABLE_ANUNCIOS_FAILURE,
    FIND_ANUNCIO_PENDING,
    FIND_ANUNCIO_SUCCESS,
    FIND_ANUNCIO_FAILURE,
    FIND_GRUPO_ANUNCIO_PENDING,
    FIND_GRUPO_ANUNCIO_SUCCESS,
    FIND_GRUPO_ANUNCIO_FAILURE,
    FIND_SUBGRUPO_ANUNCIO_PENDING,
    FIND_SUBGRUPO_ANUNCIO_SUCCESS,
    FIND_SUBGRUPO_ANUNCIO_FAILURE,
} from './actionTypes';


const initialState = { loading: false }

function anuncioReducer(state = initialState, action) {

    switch (action.type) {
        case TABLE_ANUNCIOS_PENDING:
            return {
                ...state,
                loading: true,
            }
        case TABLE_ANUNCIOS_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_ANUNCIOS_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_ANUNCIO_PENDING:
            return {
                ...state,
                loading: true,
            }
        case FIND_ANUNCIO_SUCCESS:
            return {
                ...state,
                anuncio: action.anuncio,
                loading: false
            }
        case FIND_ANUNCIO_FAILURE:
            return {
                ...state,
                anuncio: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_GRUPO_ANUNCIO_PENDING:
            return {
                ...state,
                loading: true,
            }
        case FIND_GRUPO_ANUNCIO_SUCCESS:
            return {
                ...state,
                grupo: action.grupo,
                loading: false
            }
        case FIND_GRUPO_ANUNCIO_FAILURE:
            return {
                ...state,
                grupo: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_SUBGRUPO_ANUNCIO_PENDING:
            return {
                ...state,
                loading: true,
            }
        case FIND_SUBGRUPO_ANUNCIO_SUCCESS:
            return {
                ...state,
                subGrupo: action.subGrupo,
                loading: false
            }
        case FIND_SUBGRUPO_ANUNCIO_FAILURE:
            return {
                ...state,
                subGrupo: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { anuncioReducer };