import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/propaganda/app' };
/////////////////////////////////////////////////////////////////////////////////

export function findPropagandas(params) {
  return httpService.get({ ...args, args: `/${params}` });
}

// ${WS_GET_PROPAGANDA}/${this.props.urlPropaganda}

