import React from "react";

import { AsyncPaginate } from "react-select-async-paginate";

import {
    Col,
    FormGroup,
    Label,
} from 'reactstrap';

import { Field } from 'formik';

import { listBancos } from '@handler/common/financiamento/service'

export const SelectBanco = (props) => {

    const { col, name, label, placeholder, plusChange, keyInput, nameFieldSetNull } = props;

    return (
        <Col md={col}>
            <FormGroup>
                <Label htmlFor={'id_' + name} >{label}</Label>
                <Field name={name} >
                    {({ field, form, meta }) => (
                        <>
                            <AsyncPaginate
                                {...field}
                                required={true}
                                id={'id_' + name}
                                key={'id_' + keyInput}
                                options={[]}
                                value={field.value}
                                loadOptions={async (search) => {
                                    const registros = await listBancos("parceiroBanco/open/all");
console.log(registros)
                                    let filteredOptions;
                                    if (!search) {
                                        filteredOptions = registros;
                                    } else {
                                        const searchLower = search.toLowerCase();

                                        filteredOptions = registros.filter(({ nome }) =>
                                            nome.toLowerCase().includes(searchLower)
                                        );
                                    }

                                    return {
                                        options: filteredOptions,
                                        hasMore: false
                                    };
                                }}
                                onChange={(e) => {
                                    form.setFieldValue(field.name, e);
                                    if (nameFieldSetNull !== undefined) {
                                        form.setFieldValue(nameFieldSetNull, null);
                                    }
                                    plusChange(e);
                                }}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.nome}
                                placeholder={placeholder}
                                isClearable={true}
                                isSearchable={true}
                                escapeClearsValue={true}
                                autoload={false}
                                loadOptionsOnMenuOpen={true}
                                loadingMessage={() => 'Carregando...'}
                                noOptionsMessage={() => 'Nenhum registro encontrado'}
                                isValidNewOption={meta.error && meta.touched}
                                menuPosition={'fixed'}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#e0dede',
                                        primary50: '#e0dede',
                                        primary: '#175B28',

                                        neutral20: meta.touched && meta.error ? 'red' : '#CCC'
                                    },
                                })}
                            />
                            {meta.touched && (meta.error && <p style={{ color: "red", fontSize: "12px", padding: "2px" }}>{meta.error}</p>)}
                        </>
                    )}
                </Field>
            </FormGroup>
        </Col>
    );
};

SelectBanco.defaultProps = {
    col: 12,
    plusChange: () => { }
};


