import React from "react";
import { withRouter, Link } from "react-router-dom";
import Slidewithcaption from "../../../@components/common/SlideWithCaption";
import { Button, Row } from "reactstrap";
import { connect } from 'react-redux';
import { PageLoaderScreen } from "../../../@components/common/PageLoaderScreen";
import { FIND_ANUNCIO, GET_CONFIG } from "@handler";
import ModalProposta from "../modal/modalProposta";
import { formatNumber } from "@components/common/format";
import ModalFinanciamento from "../modal/modalFinanciamento";
import { trackEvent } from "mixpanel";

class Anuncio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showProposta: false,
            showFinanciamento: false,
        };
        this.showPropostaModal = this.showPropostaModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showFinanciamentoModal = this.showFinanciamentoModal.bind(this);
        this.hideFinancModal = this.hideFinancModal.bind(this);
        this.getWhats = this.getWhats.bind(this);
        this.mountAnimalInfo = this.mountAnimalInfo.bind(this);
        this.mountPropRuralInfo = this.mountPropRuralInfo.bind(this);
        this.mountMaqAgricolaInfo = this.mountMaqAgricolaInfo.bind(this);
        this.mountImovelUrbanoInfo = this.mountImovelUrbanoInfo.bind(this);
        this.handleInfoTipo = this.handleInfoTipo.bind(this);
        this.getTipo = this.getTipo.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.anuncioReducer.anuncio && this.props.anuncioReducer.anuncio) {
            const { tipo } = this.props.match.params;
            const anuncio = this.props.anuncioReducer.anuncio;
            const lote = anuncio.lote;
            const titulo = anuncio.titulo;
            trackEvent(`[Web] - Acessou o Anúncio de ${tipo === "maquinario" ? "Maquinários" : (tipo === "imovel" ? "Imóvel Urbano" : tipo.charAt(0).toUpperCase() + tipo.slice(1))}: Lote ${lote} - ${titulo}`);
        }
    }

    showPropostaModal = () => {
        this.setState({ showProposta: true });
    };

    showFinanciamentoModal = () => {
        this.setState({ showFinanciamento: true });
    };

    hideModal = () => {
        this.setState({ showProposta: false });
    };

    hideFinancModal = () => {
        this.setState({ showFinanciamento: false });
    };

    componentDidMount() {
        const { id, tipo } = this.props.match.params;
        this.props.findAnuncio(this.getTipo(tipo), id);
        this.props.getConfig();
    }

    handleWhatsAppClick = (anuncio, tipo) => {
        trackEvent(`[Web] - Mais info via WhatsApp [${tipo === "maquinario" ? "Maquinários" : (tipo === "imovel" ? "Imóvel Urbano" : tipo.charAt(0).toUpperCase() + tipo.slice(1))}]: Lote: ${anuncio.lote} - ${anuncio.titulo}`);
        const whatsappURL = this.getWhats(anuncio, tipo);
        window.open(whatsappURL, "_blank");
    }

    getWhats = (anuncio, tipo) => {
        let telefone = '';
        let mensagem = '';

        if (anuncio.parceiroExterno !== null && anuncio.parceiroExterno !== undefined) {
            if (anuncio.parceiroExterno.nome !== '') {
                telefone = anuncio.parceiroExterno.telefone;
                mensagem = `Olá ${anuncio.parceiroExterno.nome}, estou interessado no lote ${anuncio.lote} - ${anuncio.titulo}. Fico no aguardo para maiores informações.`;
            }
        } else {
            const { configReducer } = this.props;
            const config = configReducer.config;
            if (configReducer.config !== null && configReducer.config !== undefined) {
                switch (tipo) {
                    case "animal":
                        telefone = config.whatsApp;
                        break;
                    case "propriedade":
                        telefone = config.whatsAppProp;
                        break;
                    case "maquinario":
                        telefone = config.whatsAppMaq;
                        break;
                    case "imovel":
                        telefone = config.whatsAppImovel;
                        break;
                    default:
                        telefone = '';
                        break;
                }
            }
            mensagem = `Olá, vi no App Gado Bom e estou interessado no lote ${anuncio.lote} - ${anuncio.titulo}. Fico no aguardo para maiores informações.`;
        }

        return `https://api.whatsapp.com/send?text=${mensagem}&phone=+55${telefone}`;
    }

    renderCidade(item) {
        try {
            if (item.pais.codigo === 1058) {
                if (item.cidade !== null && item.cidade !== undefined) {
                    return `${item.cidade.nome}/${item.cidade.estado.sigla}`;
                }
            } else {
                if (item.cidadeExterior === undefined) {
                    return `${item.pais.nome}`;
                } else {
                    if (item.cidadeExterior !== null) {
                        return `${item.cidadeExterior} - ${item.pais.nome}`;
                    }
                }
            }
            return 'Não informada';
        } catch (err) {
            return 'Não informada';
        }
    }

    mountAnimalInfo(anuncio) {
        return (<>
            <Row className="spaceBetween">
                <p>{anuncio.quantidade === "" || anuncio.quantidade === null
                    ? <React.Fragment /> : `Qtde.: ${anuncio.quantidade}`}</p>
                <p>{anuncio.pesoAproximado === "" || anuncio.pesoAproximado === null
                    ? <React.Fragment /> : `Peso Aprox.: ${formatNumber(anuncio.pesoAproximado ?? 0, 2)} kg`}</p>
            </Row>
            <Row className="spaceBetween">
                <p>{`Idade Média: ${anuncio.idade}`}</p>
            </Row>
            <Row className="spaceBetween">
                <p>{`Raça: ${anuncio.raca}`}</p>
            </Row>
            <Row className="spaceBetween anuncioValor">
                <p>{anuncio.valorKg === "" || anuncio.valorKg === null
                    ? <React.Fragment /> : `R$: ${formatNumber(anuncio.valorKg ?? 0, 2)}/kg`}</p>
                <p>{anuncio.valor === "" || anuncio.valor === null
                    ? <React.Fragment /> : `R$: ${formatNumber(anuncio.valor ?? 0, 2)}/unid.`}</p>
            </Row>
        </>
        )
    }

    mountPropRuralInfo(anuncio) {
        return (<>
            <Row className="spaceBetween">
                <p>{anuncio.areaTotal === "" || anuncio.areaTotal === null
                    ? <React.Fragment /> : `Área: ${formatNumber(anuncio.areaTotal ?? 0, 2)}/ha`}</p>
                <p>{anuncio.valor === "" || anuncio.valor === null
                    ? <React.Fragment /> : `R$: ${formatNumber(anuncio.valor ?? 0, 2)}/ha`}</p>
            </Row>
        </>
        )
    }

    mountImovelUrbanoInfo(anuncio) {
        return (<>
            <Row className="spaceBetween">
                <p>{anuncio.areaTotal === "" || anuncio.areaTotal === null
                    ? <React.Fragment /> : `Área: ${anuncio.areaTotal}`}</p>
                <p>{anuncio.valor === "" || anuncio.valor === null
                    ? <React.Fragment /> : `R$: ${formatNumber(anuncio.valor ?? 0, 2)}`}</p>
            </Row>
        </>
        )
    }

    mountMaqAgricolaInfo(anuncio) {
        return (<>
            <Row className="spaceBetween anuncioValor">
                <p>{anuncio.quantidade === "" || anuncio.quantidade === null
                    ? <React.Fragment /> : `Qtde.: ${anuncio.quantidade}`}</p>
                <p>{anuncio.valor === "" || anuncio.valor === null
                    ? <React.Fragment /> : `R$: ${formatNumber(anuncio.valor ?? 0, 2)}/unid.`}</p>
            </Row>
        </>
        )
    }

    handleInfoTipo(tipo, item) {
        switch (tipo) {
            case "animal":
                return this.mountAnimalInfo(item);
            case "propriedade":
                return this.mountPropRuralInfo(item);
            case "maquinario":
                return this.mountMaqAgricolaInfo(item);
            case "imovel":
                return this.mountImovelUrbanoInfo(item);
            default:
                return '';
        }
    }

    getTipo = (titulo) => {
        switch (titulo) {
            case "animal":
                return "animal";
            case "propriedade":
                return "propRural";
            case "maquinario":
                return "maqAgricola";
            case "imovel":
                return "imovelUrbano";
            default:
                return '';
        }
    }

    render() {

        const { anuncioReducer } = this.props;
        const anuncio = anuncioReducer.anuncio;
        const { tipo } = this.props.match.params;

        return (
            <React.Fragment>
                <PageLoaderScreen loading={anuncioReducer.loading} />

                <ModalFinanciamento
                    showProposta={this.state.showFinanciamento}
                    handleClose={this.hideFinancModal}
                    item={anuncio}
                    tipoAnuncio={this.getTipo(tipo)}
                />

                <ModalProposta
                    showProposta={this.state.showProposta}
                    handleClose={this.hideModal}
                    item={anuncio}
                    tipoAnuncio={this.getTipo(tipo)}
                />

                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4 className="font-size-18">
                                    {anuncio === undefined || anuncio === null
                                        ? <React.Fragment /> : anuncio.titulo}
                                </h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/#">Gado Bom</Link></li>
                                    <li className="breadcrumb-item">
                                        <Link to="/#">{
                                            tipo === "maquinario" ? "Maquinários"
                                                : (tipo === "imovel" ? "Imóvel Urbano" : tipo.charAt(0).toUpperCase() + tipo.slice(1))
                                        }</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        {anuncio === undefined || anuncio === null
                                            ? <React.Fragment /> : anuncio.titulo}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                {anuncio === undefined || anuncio === null || anuncio === ''
                                    ? <div className="card-body">
                                        <h5>Anúncio não encontrado ou inexistente!</h5>
                                    </div>
                                    :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                {
                                                    (anuncio.galeria === undefined || anuncio.galeria === null ||
                                                        anuncio.galeria.length === 0) && (
                                                            anuncio.videos === undefined || anuncio.videos === null ||
                                                            anuncio.videos.length === 0
                                                        )
                                                        ? <img
                                                            className="cardImage"
                                                            src={`https://appgadobom.com.br/app/default/sem-foto.jpg`}
                                                            alt=""
                                                        /> :
                                                        <Slidewithcaption galeria={anuncio.galeria} videos={anuncio.videos} />
                                                }
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="anuncioContent">
                                                    <Row>
                                                        <h4>{anuncio.titulo}</h4>
                                                    </Row>
                                                    {
                                                        anuncio.financiavel || anuncio.negociacao ? <center>
                                                            {anuncio.financiavel ? <span className="badge badge-success" style={{ marginRight: '5px' }}>Financiável</span> : <React.Fragment />}
                                                            {anuncio.negociacao ? <span className="badge badge-danger">Em negociação</span> : <React.Fragment />}
                                                        </center>
                                                            : <React.Fragment />
                                                    }
                                                    <div className="anuncioInfo">
                                                        <Row className="spaceBetween">
                                                            <p>{anuncio.lote === "" || anuncio.lote === null
                                                                ? <React.Fragment /> : `Lote: ${anuncio.lote}`}</p>
                                                            <p>{anuncio.cidade === "" || anuncio.cidade === null
                                                                ? <React.Fragment /> : `Cidade: ${this.renderCidade(anuncio)}`}</p>
                                                        </Row>

                                                        {anuncio.parceiroExterno !== null && anuncio.parceiroExterno !== undefined ?
                                                            <Row className="spaceBetween">
                                                                <p>Parceiro: {anuncio.parceiroExterno.nome}</p>
                                                            </Row> : <React.Fragment />
                                                        }

                                                        {this.handleInfoTipo(tipo, anuncio)}

                                                        <Row className={"text-justify"}>
                                                            <p style={{ whiteSpace: "pre-wrap" }}>
                                                                {anuncio.descricao === "" || anuncio.descricao === null
                                                                    ? <React.Fragment /> : `${anuncio.descricao}`}
                                                            </p>
                                                        </Row>
                                                    </div>
                                                </div>

                                                <div className="button-items">
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={this.showPropostaModal}
                                                    >
                                                        Enviar Proposta
                                                    </Button>

                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => this.handleWhatsAppClick(anuncio, tipo)}
                                                    >
                                                        Chamar WhatsApp
                                                    </Button>

                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={this.showFinanciamentoModal}
                                                    >
                                                        Solicitar Financiamento
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-secondary" style={{ marginTop: '10px' }} onClick={this.props.history.goBack}>VOLTAR</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { anuncioReducer, configReducer } = state.entities;
    return { anuncioReducer, configReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        findAnuncio: (args, id) => dispatch({ type: FIND_ANUNCIO, args, id }),
        getConfig: (_) => dispatch({ type: GET_CONFIG }),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Anuncio))