import { path, pathBanco, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/' };
const argsBanco = { ...pathBanco, path: '/' };

export function listBancos(param) {
  return httpService.get({ ...args, args: param });
}

export function listAgencias(param) {
  return httpService.get({ ...argsBanco, args: param });
}

export function listProgramas(param) {
  return httpService.get({ ...argsBanco, args: param });
}

