export const TABLE_ANUNCIOS = 'TABLE_ANUNCIOS'
export const TABLE_ANUNCIOS_PENDING = 'TABLE_ANUNCIOS_PENDING'
export const TABLE_ANUNCIOS_SUCCESS = 'TABLE_ANUNCIOS_SUCCESS'
export const TABLE_ANUNCIOS_FAILURE = 'TABLE_ANUNCIOS_FAILURE'

export const FIND_ANUNCIO = 'FIND_ANUNCIO'
export const FIND_ANUNCIO_PENDING = 'FIND_ANUNCIO_PENDING'
export const FIND_ANUNCIO_SUCCESS = 'FIND_ANUNCIO_SUCCESS'
export const FIND_ANUNCIO_FAILURE = 'FIND_ANUNCIO_FAILURE'

export const FIND_GRUPO_ANUNCIO = 'FIND_GRUPO_ANUNCIO'
export const FIND_GRUPO_ANUNCIO_PENDING = 'FIND_GRUPO_ANUNCIO_PENDING'
export const FIND_GRUPO_ANUNCIO_SUCCESS = 'FIND_GRUPO_ANUNCIO_SUCCESS'
export const FIND_GRUPO_ANUNCIO_FAILURE = 'FIND_GRUPO_ANUNCIO_FAILURE'

export const FIND_SUBGRUPO_ANUNCIO = 'FIND_SUBGRUPO_ANUNCIO'
export const FIND_SUBGRUPO_ANUNCIO_PENDING = 'FIND_SUBGRUPO_ANUNCIO_PENDING'
export const FIND_SUBGRUPO_ANUNCIO_SUCCESS = 'FIND_SUBGRUPO_ANUNCIO_SUCCESS'
export const FIND_SUBGRUPO_ANUNCIO_FAILURE = 'FIND_SUBGRUPO_ANUNCIO_FAILURE'