import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../css/style.css";

// import images
import logolightImg from "../../assets/images/logo-light.png";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Toggle sidebar
   */
   toggleMenu() {
    // this.props.toggleMenuCallback();
    this.props.openLeftMenuCallBack();
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div>
                <Link to="/" className="logo logo-light mr-4 ">
                  <span className="logo-sm">
                    <img src={logolightImg} alt="" height="70" />
                  </span>
                  <span className="logo-lg">
                    <img src={logolightImg} alt="" width="180" />
                  </span>
                </Link>
              </div>
            </div>

            <div className="row" style={{alignItems: "center", verticalAlign: "middle", justifyContent: "center" }}>
              <div className="d-flex">
                <p className="item-phone">(55) 3422-8558&nbsp;|&nbsp;</p>
                <p className="item-phone">(55) 9 9964-1149&nbsp;|&nbsp;</p>
                <p className="item-phone">(55) 9 9626-3179</p>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-light ml-2 mr-2 font-size-12 d-lg-none waves-effect waves-light"
                  onClick={this.toggleMenu}
                >
                  <i className="mdi mdi-menu"></i>
                </button>
              </div>
            </div>

          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default TopBar;
