import {
    LIST_BANCOS_PENDING,
    LIST_BANCOS_SUCCESS,
    LIST_BANCOS_FAILURE,
    LIST_AGENCIAS_PENDING,
    LIST_AGENCIAS_SUCCESS,
    LIST_AGENCIAS_FAILURE,
    LIST_PROGRAMAS_PENDING,
    LIST_PROGRAMAS_SUCCESS,
    LIST_PROGRAMAS_FAILURE,
} from './actionTypes';


const initialState = { loading: false }

function financiamentoReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_BANCOS_PENDING:
            return {
                ...state,
                loading: true,
            }
        case LIST_BANCOS_SUCCESS:
            return {
                ...state,
                bancos: action.bancos,
                loading: false
            }
        case LIST_BANCOS_FAILURE:
            return {
                ...state,
                bancos: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_AGENCIAS_PENDING:
            return {
                ...state,
                loading: true,
            }
        case LIST_AGENCIAS_SUCCESS:
            return {
                ...state,
                agencias: action.agencias,
                loading: false
            }
        case LIST_AGENCIAS_FAILURE:
            return {
                ...state,
                agencias: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PROGRAMAS_PENDING:
            return {
                ...state,
                loading: true,
            }
        case LIST_PROGRAMAS_SUCCESS:
            return {
                ...state,
                programas: action.programas,
                loading: false
            }
        case LIST_PROGRAMAS_FAILURE:
            return {
                ...state,
                programas: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { financiamentoReducer };