import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { InputText, InputTextArea } from "@components/form";
import { AlertSuccess, AlertError } from "@components/common/AlertToast";

import { Link } from "react-router-dom";
import { WS_URL_WS_CENTRAL } from "@commons/Const";
import { trackEvent } from "mixpanel";

import ReactGA from 'react-ga';

class Formulario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success_msg: false,
            value: "[empty]",
            load: false,
            expired: "false",
            isAgreed: false,
            tipoForm: this.props.location.pathname.slice(1),
            btnText: "Enviar"
        };

        this.formSubmit = this.formSubmit.bind(this);
    }

    changeBtnText = btnText => {
        this.setState({ btnText });
    };

    formSubmit(values) {
        this.changeBtnText("Enviando...");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: values.nome,
                telefone: values.telefone,
                email: values.email,
                cidade: values.cidade,
                proposta: values.mensagem,
            })
        };
        let url = "";
        if (this.state.tipoForm === "encomendar") {
            url = `${WS_URL_WS_CENTRAL}/encomenda`;
            ReactGA.event({
                category: 'QueroEncomendar',
                action: 'Solicitação de encomenda'
            });
        } else {
            url = `${WS_URL_WS_CENTRAL}/venda`;
            ReactGA.event({
                category: 'QueroVender',
                action: 'Solicitação de venda'
            });
        }
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(() => {
                values.nome = "";
                values.telefone = "";
                values.email = "";
                values.cidade = "";
                values.mensagem = "";
                this.setState({ btnText: "Enviar" });
                AlertSuccess('Mensagem enviada com sucesso! Aguarde que em breve entraremos em contato.');
                if (this.state.tipoForm === "encomendar") {
                    trackEvent('[Web] - Formulário de Solicitação de Encomenda enviado com sucesso');
                } else {
                    trackEvent('[Web] - Formulário de Solicitação de Venda enviado com sucesso');
                }
            }
            ).catch(() => {
                this.setState({ btnText: "Enviar" });
                AlertError('Sua mensagem não foi enviada. Algo inesperado aconteceu. Tente novamente ou entre em contato via nosso canal de WhatsApp.');
            });
    }

    render() {

        const { tipoForm } = this.state

        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="fas fa-home h2"></i>
                    </Link>
                </div>
                <div className="account-pages my-3 pt-3">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={10} lg={8} xl={7}>
                                <div className="position-relative">

                                    <Card className="overflow-hidden">
                                        <div className="bg-primary">
                                            <div className="text-primary text-center p-4">
                                                <h5 className="text-white font-size-20">
                                                    Envie o que deseja {tipoForm}
                                                </h5>
                                                <h5 className="text-white font-size-18">
                                                    Entraremos em contato!
                                        </h5>
                                            </div>
                                        </div>

                                        <CardBody className="p-4">
                                            <div className="">
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={{ nome: '', telefone: '', email: '', cidade: '', mensagem: '', recaptcha: '' }}
                                                    validationSchema={
                                                        Yup.object().shape({
                                                            nome: Yup.string()
                                                                .min(2, 'Muito curto!')
                                                                .max(50, 'Campo deve ter menos do que 50 caracteres!')
                                                                .required('Campo Obrigatório'),
                                                            telefone: Yup.string()
                                                                .min(10, 'Muito curto!')
                                                                .max(20, 'Campo deve ter menos do que 20 caracteres!')
                                                                .required('Campo Obrigatório'),
                                                            email: Yup.string()
                                                                .email('E-mail Inválido'),
                                                            cidade: Yup.string()
                                                                .min(2, 'Muito curto!')
                                                                .max(50, 'Campo deve ter menos do que 50 caracteres!')
                                                                .required('Campo Obrigatório'),
                                                            mensagem: Yup.string()
                                                                .min(2, 'Muito curto!')
                                                                .max(4000, 'Campo deve ter menos do que 4000 caracteres!')
                                                                .required('Campo Obrigatório'),
                                                            recaptcha: Yup.string(),
                                                        })
                                                    }
                                                    onSubmit={(values) => this.formSubmit(values)}
                                                >
                                                    {(errors, touched, setFieldValue) => (
                                                        <Form autoComplete="off">
                                                            <InputText label="Nome" name='nome' placeholder='Informe seu Nome' />
                                                            <InputText label="Telefone" name='telefone' placeholder='Informe seu Telefone' />
                                                            <InputText label="E-mail (Opcional)" name='email' placeholder='Informe seu E-mail' />
                                                            <InputText label="Cidade/Estado" name='cidade' placeholder='Informe sua Cidade e Estado' />
                                                            { tipoForm === "encomendar" ?
                                                                <InputTextArea label="Descrição" name='mensagem' placeholder='Informe o que deseja encomendar' />
                                                                : <InputTextArea label="Descrição" name='mensagem' placeholder='Informe o que deseja vender' />
                                                            }
                                                            <Col className="text-right">
                                                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                                                    <i className="fas fa-paper-plane mr-2" />{this.state.btnText}
                                                                </button>
                                                            </Col>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Formulario;