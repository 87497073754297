import React from "react";

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { PageLoaderScreen } from "../../@components/common/PageLoaderScreen";

import { FIND_REGULAMENTO, } from '@handler';
import { trackEvent } from "mixpanel";

class Regulamento extends React.Component {

    // ///////////////////////////////////////////////////////////////////////////////
    componentDidMount() {
        this.props.findRegulamento()
        trackEvent('[Web] - Acessou a Página do Regulamento');
    }
    // ///////////////////////////////////////////////////////////////////////////////

    render() {

        const { regulamentoReducer } = this.props;

        return (
            <React.Fragment>
                <PageLoaderScreen loading={regulamentoReducer.loading} />

                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Regulamento</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/#">Gado Bom</Link></li>
                                    <li className="breadcrumb-item active">Regulamento</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col">
                                        {/* <h4>REGULAMENTO E CONDIÇÕES GERAIS DE USO DO APLICATIVO GADO BOM</h4> */}
                                        <p className="text-justify" style={{whiteSpace: "pre-wrap"}}>
                                            {regulamentoReducer.regulamento === undefined || regulamentoReducer.regulamento === null
                                                ? <React.Fragment/>
                                                : regulamentoReducer.regulamento.descricao}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export function mapStateToProps(state) {
    const { regulamentoReducer } = state.entities;
    return { regulamentoReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        findRegulamento: (_) => dispatch({ type: FIND_REGULAMENTO }),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Regulamento))
