import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/' };



export function listAnuncios(param) {
  return httpService.get({ ...args, args: param });
}

export function findAnuncio(param, id) {
  return httpService.get({ ...args, args: `${param}/${id}` });
}

export function findGrupoAnuncio(param) { // receber param pra ficar dinamico
  return httpService.get({ ...args, args: `grupo${param}/all/` });
}

export function findSubGrupoAnuncio(param, id) {// receber param pra ficar dinamico
  return httpService.get({ ...args, args: `subGrupo${param}/findByGrupo/${id}` });
}