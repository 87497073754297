import { takeLatest, put, call } from 'redux-saga/effects';

import { findGrupoAnuncio } from '../../anuncios/service';
import {
  FIND_GRUPO_ANUNCIO,
  FIND_GRUPO_ANUNCIO_PENDING,
  FIND_GRUPO_ANUNCIO_SUCCESS,
  FIND_GRUPO_ANUNCIO_FAILURE,
} from '../../anuncios/actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaFindGrupoAnuncio(action) {
  yield put({ type: FIND_GRUPO_ANUNCIO_PENDING})

  try {

    const grupo = yield call(findGrupoAnuncio, action.args);

    yield put({ type: FIND_GRUPO_ANUNCIO_SUCCESS, grupo: grupo })

  } catch (error) {
    yield put({ type: FIND_GRUPO_ANUNCIO_FAILURE })

    AlertError('Falha ao carregar os grupos. Tente novamente...');
  }
}

export default function* watchFindGrupoAnuncio() {
  yield takeLatest(FIND_GRUPO_ANUNCIO, sagaFindGrupoAnuncio)
}
