import React from "react";
import { withRouter, Link } from "react-router-dom";
import Slidewithcaption from "../../../@components/common/SlideWithCaption";

import { connect } from 'react-redux';
import { PageLoaderScreen } from "../../../@components/common/PageLoaderScreen";
import { FIND_RECADO } from "@handler/common/recados";
import { trackEvent } from "mixpanel";


class Recado extends React.Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.findRecado('recado', id)
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.recadoReducer.recado && this.props.recadoReducer.recado) {
            const id = this.props.recadoReducer.recado.id;
            const titulo = this.props.recadoReducer.recado.titulo;
            trackEvent(`[Web] - Acessou a Notícia: ${id} - ${titulo}`);
        }
    }

    render() {

        const { recadoReducer } = this.props;
        const recado = recadoReducer.recado;

        return (
            <React.Fragment>
                <PageLoaderScreen loading={recadoReducer.loading} />

                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4 className="font-size-18">
                                    {recado === undefined || recado === null
                                        ? <React.Fragment /> : recado.titulo}
                                </h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/#">Gado Bom</Link></li>
                                    <li className="breadcrumb-item"><Link to="/recados">Recados</Link></li>
                                    <li className="breadcrumb-item active">
                                        {recado === undefined || recado === null
                                            ? <React.Fragment /> : recado.titulo}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                {recado === undefined || recado === null || recado === ''
                                    ? <div className="card-body">
                                        <h5>Recado não encontrado ou inexistente!</h5>
                                    </div>
                                    :
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                {recado === undefined || recado === null ||
                                                    ((recado.galeria === undefined || recado.galeria === null) &&
                                                        (recado.videos === undefined || recado.videos === null))
                                                    ? <React.Fragment /> : <Slidewithcaption galeria={recado.galeria} videos={recado.videos} />}
                                            </div>

                                            <div className="col-lg-6">
                                                <div>
                                                    <h4>{recado === undefined || recado === null
                                                        ? <React.Fragment /> : (recado.titulo ?? '')}</h4>
                                                </div>
                                                <div>
                                                    <p style={{ whiteSpace: "pre-wrap" }}>{recado === undefined || recado === null
                                                        ? <React.Fragment /> : (recado.mensagem ?? '')}</p>
                                                </div>
                                                <div>
                                                    <p style={{ whiteSpace: "pre-wrap" }}>{recado === undefined || recado === null ||
                                                        recado.fonte === undefined || recado.fonte === null || recado.fonte === ''
                                                        ? <React.Fragment /> : 'Fonte: ' + (recado.fonte ?? '')}</p>
                                                </div>
                                                <div>
                                                    {recado === undefined || recado === null || recado.linkUrl === undefined || recado.linkUrl === null
                                                        || recado.linkUrl === ''
                                                        ? <React.Fragment /> : <a href={recado.linkUrl} target='_blank' rel="noreferrer">Abrir Notícia</a>}
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-secondary" onClick={this.props.history.goBack}>VOLTAR</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { recadoReducer } = state.entities;
    return { recadoReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        findRecado: (args, id) => dispatch({ type: FIND_RECADO, args, id }),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Recado))

