import { takeLatest, put, call } from 'redux-saga/effects';

import { listBancos } from '../service';
import {
    LIST_BANCOS,
  LIST_BANCOS_PENDING,
  LIST_BANCOS_SUCCESS,
  LIST_BANCOS_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaListBancos(action) {
  yield put({ type: LIST_BANCOS_PENDING })

  try {
    const bancos = yield call(listBancos, action.args)

    yield put({ type: LIST_BANCOS_SUCCESS, bancos: bancos })
  } catch (error) {
    yield put({ type: LIST_BANCOS_FAILURE })

    AlertError('Falha ao carregar os Bancos. Tente novamente...');
  }
}

export default function* watchListBancos() {
  yield takeLatest(LIST_BANCOS, sagaListBancos)
}