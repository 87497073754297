import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
          }
        }
      }
    }
    return false;
  };
  render() {
    function refreshPage() {
      setTimeout(() => {
        window.location.reload(false);
      }, 100);
    }
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/animais" onClick={refreshPage}>
                        <i className="mdi mdi-cow mr-2"></i>Gado
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/maquinarios" onClick={refreshPage}>
                        <i className="mdi mdi-tractor mr-2"></i>Máquina
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/propriedades" onClick={refreshPage}>
                        <i className="mdi mdi-barn mr-2"></i>Terra
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/imovelUrbano" onClick={refreshPage}>
                        <i className="mdi mdi-barn mr-2"></i>Imóvel
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/encomendar">
                        <i className="mdi mdi-cart-arrow-down mr-2"></i>Encomendar
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/vender">
                        <i className="mdi mdi-cart-arrow-up mr-2"></i>Vender
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/recados">
                        <i className="ion ion-md-megaphone mr-2"></i>Notícias
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/regulamento">
                        <i className="mdi mdi-alert-circle mr-2"></i>Regulamento
                      </Link>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);
