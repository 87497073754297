import React from "react";
import { Col, Modal, ModalHeader, ModalBody, ModalFooter, Row, CardHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { InputText, InputMoney } from "@components/form";
import { AlertSuccess, AlertError } from "@components/common/AlertToast";
import { PageLoaderScreen } from "../../../@components/common/PageLoaderScreen";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { WS_URL_WS_CENTRAL_BANCO } from "@commons/Const";
import { LIST_AGENCIAS, LIST_BANCOS, LIST_PROGRAMAS } from "@handler";
import { SelectBanco } from "@components/search/bancos";
import { SelectAgencia } from "@components/search/agencias";
import { SelectPrograma } from "@components/search/programas";
import { trackEvent } from "mixpanel";

import ReactGA from 'react-ga';

class ModalFinanciamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnText: "Enviar",
            bancoSlcd: "",
            agenciaSlcd: "",
            programaSlcd: "",
            keyInputs: 1
        };
    }

    componentDidMount() {
        this.props.listBancos("parceiroBanco/open/all");
    }

    changeBtnText = btnText => {
        this.setState({ btnText });
    };

    formSubmit(values) {
        this.changeBtnText("Enviando...");

        ReactGA.event({
            category: 'Financiamento',
            action: 'Simulação de financiamento'
        });

        var estado = 43;
        if (values.bancoSlc.controlePorAgencia.literal) {
            estado = parseInt((`${values.agenciaSlc.cidade.id}`).substring(0, 2));
        } else {
            estado = 43;//parseInt((`${values.agenciaSlc.id}`).substring(0, 2));
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: `{ 
                    "nome": "${values.nome}",
                    "cpf": "${values.cpf}",
                    "celular": "${values.telefone}",
                    "email": "${values.email}",
                    "valor": ${values.valor},
                    "banco": {
                        "agencia": "${values.agencia}",
                        "conta": "${values.conta}"
                    },
                    "${this.props.tipoAnuncio}": {
                        "id": ${this.props.item.id}
                    },
                    "programa": {
                        "id": 1
                    },
                    "estado": {
                        "id": "${estado}"
                    },
                    "cidade": ${values.bancoSlc.controlePorAgencia.literal ?
                    `{
                            "id": "${values.agenciaSlc.cidade.id}"
                        }`
                    : null
                },
                    "notificacaoWhatsapp": {
                        "literal": ${values.bancoSlc.notificacaoWhatsapp.literal}
                    },
                    "agenciaSelecionada": ${values.bancoSlc.controlePorAgencia.literal ?
                    `{
                            "id": "${values.agenciaSlc.id}"
                        }`
                    : null
                }
                }`
        };

        if (values.bancoSlc.notificacaoWhatsapp) {
            var mensagem = mensagem = `Olá, vi no App Gado Bom e estou interessado em financiar o lote ${this.props.item.lote} - ${this.props.item.titulo}. Fico no aguardo para maiores informações.`;
            const link = `https://api.whatsapp.com/send?text=${encodeURIComponent(mensagem)}&phone=+55${values.bancoSlc.whatsapp}`;
            window.open(link, '_blank');
        }

        fetch(
            `${WS_URL_WS_CENTRAL_BANCO}/${values.bancoSlc.dominio}/simulacao`,
            requestOptions)
            .then(response => response.json())
            .then(() => {
                values.nome = "";
                values.cpf = "";
                values.telefone = "";
                values.email = "";
                values.valor = "";
                values.agencia = "";
                values.conta = "";

                values.agenciaSlc = null;
                values.programaSlc = null;

                this.setState({ btnText: "Enviar" });
                AlertSuccess('Solicitação enviada com sucesso! Aguarde que em breve entraremos em contato.');

                trackEvent(`[Web] - Solicitação de Financiamento [${this.props.tipoAnuncio}] enviada com sucesso: Lote: ${this.props.item.lote} - ${this.props.item.titulo}`);
            }
            ).catch(() => {
                this.setState({ btnText: "Enviar" });
                AlertError('Sua solicitação não foi enviada. Algo inesperado aconteceu. Tente novamente ou entre em contato via nosso canal de WhatsApp.');
            });
        this.props.handleClose();
    }

    render() {
        const { item } = this.props;
        const { financiamentoReducer } = this.props;

        return (

            <React.Fragment>
                <PageLoaderScreen loading={financiamentoReducer.loading} />

                {item === undefined || item === null
                    ? <React.Fragment />
                    :
                    <Modal isOpen={this.props.showProposta} size="xl" style={{ maxWidth: '1600px', width: '90%' }}>
                        <ModalHeader style={{ display: "flex", justifyContent: "center" }}>
                            <Row >
                                <p className="">Solicitar Financiamento</p>
                            </Row>
                        </ModalHeader>
                        <Formik
                            enableReinitialize
                            initialValues={{ bancoSlc: null, agenciaSlc: null, programaSlc: null, nome: '', cpf: '', telefone: '', email: '', agencia: '', conta: '', valor: 0, recaptcha: '' }}
                            validationSchema={
                                Yup.object().shape({
                                    bancoSlc: Yup.object()
                                        .typeError('Este campo é obrigatório')
                                        .shape({
                                            id: Yup.number()
                                                .typeError('Este campo é obrigatório')
                                                .required("Este campo é obrigatório"),
                                        }),
                                    agenciaSlc: Yup.object()
                                        .nullable()
                                        .test('required-agencia', 'Este campo é obrigatório', function (value) {
                                            const bancoSlc = this.parent.bancoSlc;
                                            if (bancoSlc && bancoSlc.controlePorAgencia.literal === true) {
                                                return value !== undefined && value !== null;
                                            }
                                            return true;
                                        }),
                                    // programaSlc: Yup.object()
                                    //     .typeError('Este campo é obrigatório')
                                    //     .shape({
                                    //         id: Yup.number()
                                    //             .typeError('Este campo é obrigatório')
                                    //             .required("Este campo é obrigatório"),
                                    //     }),
                                    nome: Yup.string()
                                        .min(2, 'Muito curto!')
                                        .max(50, 'Campo deve ter menos do que 50 caracteres!')
                                        .required('Campo Obrigatório'),
                                    cpf: Yup.string()
                                        .min(10, 'Muito curto!')
                                        .max(14, 'Campo deve ter menos do que 14 caracteres!')
                                        .required('Campo Obrigatório'),
                                    telefone: Yup.string()
                                        .min(10, 'Muito curto!')
                                        .max(20, 'Campo deve ter menos do que 20 caracteres!')
                                        .required('Campo Obrigatório'),
                                    email: Yup.string()
                                        .email('E-mail Inválido'),
                                    agencia: Yup.string()
                                        .min(2, 'Muito curto!')
                                        .max(50, 'Campo deve ter menos do que 50 caracteres!'),
                                    conta: Yup.string()
                                        .min(2, 'Muito curto!')
                                        .max(50, 'Campo deve ter menos do que 50 caracteres!'),
                                    valor: Yup.number()
                                        .typeError('Este campo é obrigatório')
                                        .min(0, 'Por Favor, informe um valor válido'),
                                    recaptcha: Yup.string(),
                                })
                            }
                            onSubmit={(values) => this.formSubmit(values)}
                        >
                            {({ errors, touched, values, setFieldValue }) => (
                                <Form autoComplete="off">

                                    <ModalBody>
                                        <div style={{ marginBottom: "15px", backgroundColor: '#012F20', padding: "10px" }}>
                                            <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'white', fontSize: "20px" }}>Lote: {item.lote}</h2>
                                            <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: 'white', fontSize: "20px" }}>{item.titulo}</h2>
                                        </div>

                                        <CardHeader>
                                            <Row className="col-sm-12 pr-0">
                                                <SelectBanco
                                                    name='bancoSlc'
                                                    label='Banco'
                                                    placeholder='Selecione o Banco'
                                                    col={4}
                                                    plusChange={(e) => {
                                                        setFieldValue('agenciaSlc', null)
                                                        setFieldValue('programaSlc', null)
                                                        this.setState({
                                                            keyInputs: (this.state.keyInputs + 1)
                                                        })
                                                    }}
                                                />

                                                {values.bancoSlc === undefined || values.bancoSlc === null
                                                    || !values.bancoSlc.controlePorAgencia.literal ?
                                                    <React.Fragment /> :
                                                    <>
                                                        <SelectAgencia
                                                            keyInput={this.state.keyInputs}
                                                            name='agenciaSlc'
                                                            label='Agência'
                                                            placeholder='Selecione a Agência'
                                                            bancoSlcd={values.bancoSlc}
                                                            col={4}
                                                        />
                                                        {/* <SelectPrograma
                                                            keyInput={this.state.keyInputs}
                                                            name='programaSlc'
                                                            label='Programa'
                                                            placeholder='Selecione o Programa'
                                                            bancoSlcd={values.bancoSlc.dominio}
                                                            col={4}
                                                        /> */}
                                                    </>
                                                }
                                            </Row>
                                        </CardHeader>

                                        <div className="mt-3">
                                            <InputText label="Nome Completo" name='nome' placeholder='Informe seu Nome' />
                                            <InputText label="CPF" name='cpf' placeholder='Informe seu CPF' />
                                            <InputText label="Telefone" name='telefone' placeholder='Informe seu Telefone' />
                                            <InputText label="E-mail (Opcional)" name='email' placeholder='Informe seu E-mail' />
                                            <InputText label="Agência - Nro. (Opcional)" name='agencia' placeholder='Informe sua Agência (Número)' />
                                            <InputText label="Conta Corrente (Opcional)" name='conta' placeholder='Informe sua Conta Corrente' />
                                            <InputMoney
                                                name={`valor`}
                                                label='Valor Solicitado (Opcional)'
                                                placeholder='Informe o valor'
                                                col={12}
                                            />
                                            <Col className="text-right" style={{ display: "flex", justifyContent: "space-between" }}>
                                            </Col>
                                        </div>
                                    </ModalBody>

                                    <ModalFooter>
                                        <button className="btn btn-light w-md waves-effect waves-light" onClick={this.props.handleClose}>
                                            <i className="fas fa-times-circle mr-2" />Fechar
                                        </button>
                                        <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                            <i className="fas fa-paper-plane mr-2" />{this.state.btnText}
                                        </button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { financiamentoReducer } = state.entities;
    return { financiamentoReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        listBancos: (args) => dispatch({ type: LIST_BANCOS, args }),
        listAgencias: (args) => dispatch({ type: LIST_AGENCIAS, args }),
        listProgramas: (args) => dispatch({ type: LIST_PROGRAMAS, args }),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalFinanciamento))