import { 
    WS_URL_WS_CENTRAL,
    WS_URL_WS_CENTRAL_BANCO
 } from '@commons/Const';

export const path = {
    base:`${WS_URL_WS_CENTRAL}`
};

export const pathBanco = {
    base:`${WS_URL_WS_CENTRAL_BANCO}`
};
