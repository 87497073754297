import { takeLatest, put, call } from 'redux-saga/effects';

import { findRegulamento } from '../service';
import {
  FIND_REGULAMENTO,
  FIND_REGULAMENTO_PENDING,
  FIND_REGULAMENTO_SUCCESS,
  FIND_REGULAMENTO_FAILURE
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaFindRegulamento() {
  yield put({ type: FIND_REGULAMENTO_PENDING })

  try {

    const regulamento = yield call(findRegulamento);

    yield put({ type: FIND_REGULAMENTO_SUCCESS, regulamento: regulamento })
  } catch (error) {
    yield put({ type: FIND_REGULAMENTO_FAILURE })

    AlertError('Falha ao carregar o regulamento Tente novamente...');
  }
}

export default function* watchFindRegulamento() {
  yield takeLatest(FIND_REGULAMENTO, sagaFindRegulamento)
}
