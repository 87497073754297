import watchListBancos from './saga/listBancos';
import watchListAgencias from './saga/listAgencias';
import watchListProgramas from './saga/listProgramas';

export const financiamentoSaga = [
    watchListBancos(),
    watchListAgencias(),
    watchListProgramas(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
