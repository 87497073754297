import React from "react";
import { withRouter, Link } from "react-router-dom";
import { TableView } from "@components/form";

import { connect } from 'react-redux';
import { PageLoaderScreen } from "../../@components/common/PageLoaderScreen";
import {
  TABLE_ANUNCIOS,
  FIND_GRUPO_ANUNCIO,
  FIND_SUBGRUPO_ANUNCIO,
} from "@handler/common/anuncios";

// import Ads from "../../components/HorizontalLayout/Ads";
import CardAnuncio from "./components/CardAnuncio";
import { CardHeader } from "reactstrap";
import Propagandas from "@components/propagandas";

class Anuncios extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tipoAnuncio: this.props.location.pathname.slice(1),
      grupo: '',
      subGrupo: '',
    }

    this.onPressButton = this.onPressButton.bind(this);
    this.renderGrupo = this.renderGrupo.bind(this);
    this.handleGrupo = this.handleGrupo.bind(this);
    this.handleSubGrupo = this.handleSubGrupo.bind(this);
    this.getTipo = this.getTipo.bind(this);
  }

  componentDidMount() {
    const { tipoAnuncio } = this.state;
    const param = this.getTipo(tipoAnuncio)
    this.props.findGrupoAnuncio(param.charAt(0).toUpperCase() + param.slice(1))
  }

  onPressButton(selectedIndex) {
    this.props.setSelectedIndexGrupo(selectedIndex);
    this.props.getSubGrupoFiltro(this.props.urlSubGrupo, selectedIndex);
    this.props.getAnuncios(this.props.url);
  }

  renderGrupo(grupo) {
    return (
      grupo.map(item =>
        <option key={item.id} value={item.id}>{item.nome}</option>
      )
    );
  }

  async handleGrupo(e) {
    const { tipoAnuncio } = this.state;
    const param = this.getTipo(tipoAnuncio)

    let state = this.state;
    // eslint-disable-next-line 
    if (e.target.value === undefined || e.target.value === null || e.target.value == -1) {
      state.grupo = '';
    } else {
      state.grupo = `&grupo=${e.target.value}`;
    }
    state.subGrupo = '';

    this.setState(state);

    await this.props.findSubGrupoAnuncio((param.charAt(0).toUpperCase() + param.slice(1)), e.target.value);
    await this.props.listTableAnuncios(`${this.getTipo(this.state.tipoAnuncio)}?size=15&page=0&status=1${this.state.grupo}${this.state.subGrupo}`);
  }

  async handleSubGrupo(e) {
    let state = this.state;
    // eslint-disable-next-line 
    if (e.target.value === undefined || e.target.value === null || e.target.value == -1) {
      state.subGrupo = '';
    } else {
      state.subGrupo = `&subGrupo=${e.target.value}`;
    }
    this.setState(state);
    await this.props.listTableAnuncios(`${this.getTipo(this.state.tipoAnuncio)}?size=15&page=0&status=1${this.state.grupo}${this.state.subGrupo}`);
  }

  getTipo = (titulo) => {
    switch (titulo) {
      case "animais":
        return "animal";
      case "propriedades":
        return "propRural";
      case "maquinarios":
        return "maqAgricola";
      case "imovelUrbano":
        return "imovelUrbano";
      default:
        return '';
    }
  }

  render() {

    const { tipoAnuncio, grupo, subGrupo } = this.state;
    const titulo = tipoAnuncio.charAt(0).toUpperCase() + tipoAnuncio.slice(1);
    const { anuncioReducer } = this.props;

    const itemGrid = (item, layout) => {
      if (item === null) {
        return <React.Fragment />;
      }
      // if (layout === 'list')
      //   return <CardRecado item={item} /> // criar uma view para modo lista
      // else if (layout === 'grid')
      return <CardAnuncio tipo={tipoAnuncio} item={item} /> // criar uma view para modo grid
    }

    return (
      <React.Fragment>
        <PageLoaderScreen loading={anuncioReducer.loading} />

        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="page-title-box">
                <h4 className="font-size-18">
                  {titulo === "Maquinarios"
                    ? "Maquinários" : (titulo === "ImovelUrbano" ? "Imóvel Urbano" : titulo)}
                </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Gado Bom</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/#">
                      {titulo === "Maquinarios"
                        ? "Maquinários" : (titulo === "ImovelUrbano" ? "Imóvel Urbano" : titulo)}
                    </Link>
                  </li>
                </ol>
              </div>
            </div>

            {/* <div className="col-sm-6">
              <div className="float-right d-none d-md-block">
                <button className="btn btn-primary w-md waves-effect waves-light" type="button">
                    <i className="fas fa-filter mr-2"/>Filtrar
                </button>
              </div>
            </div> */}
          </div>

          <CardHeader>
            <div className="row col-sm-12">
              <h6 style={{ fontWeight: 'bold', marginRight: '5px' }}>Grupo: </h6>
              <select value={this.props.is_expenditure} onChange={(e) => this.handleGrupo(e)}>
                <option defaultValue value={-1}>Todos</option>
                {anuncioReducer === undefined || anuncioReducer === null ||
                  anuncioReducer.grupo === undefined || anuncioReducer.grupo === null
                  ? <React.Fragment/> : this.renderGrupo(anuncioReducer.grupo)}
              </select>

              {anuncioReducer === undefined || anuncioReducer === null ||
                anuncioReducer.subGrupo === undefined || anuncioReducer.subGrupo === null ||
                anuncioReducer.subGrupo.length === 0
                ? <React.Fragment/> : <>
                  <h6 style={{ fontWeight: 'bold', marginRight: '5px', marginLeft: '20px' }}>Sub Grupo: </h6>
                  <select value={this.props.is_expenditure} onChange={(e) => this.handleSubGrupo(e)}>
                    <option defaultValue value={-1}>Todos</option>
                    {this.renderGrupo(anuncioReducer.subGrupo)}
                  </select>
                </>}
            </div>
          </CardHeader>
          <br />

          <TableView
            reducer={anuncioReducer}
            list={this.props.listTableAnuncios}
            tipo={this.getTipo(tipoAnuncio)}
            where={`status=1${grupo}${subGrupo}`} // status=1&grupo=1&subGrupo=1
            itemGrid={itemGrid}
          />

          {/* Ads */}
          <div className="row mb-4 col-sm-12 col-md-6 col-lg-6 mx-auto">
            <Propagandas urlPropaganda={this.getTipo(tipoAnuncio)}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state) {
  const { anuncioReducer } = state.entities;
  return { anuncioReducer };
};

function mapDispatchToProps(dispatch) {
  return {
    listTableAnuncios: (args) => dispatch({ type: TABLE_ANUNCIOS, args }),
    findGrupoAnuncio: (args) => dispatch({ type: FIND_GRUPO_ANUNCIO, args }),
    findSubGrupoAnuncio: (args, id) => dispatch({ type: FIND_SUBGRUPO_ANUNCIO, args, id }),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Anuncios));