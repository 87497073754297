import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/' };

export function listRecados(param) {
  return httpService.get({ ...args, args: param });
}

export function findRecado(param, id) {
  return httpService.get({ ...args, args: `${param}/${id}` });
}
