import { all } from 'redux-saga/effects';

import LayoutSaga from '../store/layout/saga';

import { regulamentoSaga, regulamentoReducer } from './common/regulamento';
import { recadoSaga, recadoReducer } from './common/recados';
import { anuncioSaga, anuncioReducer } from './common/anuncios';
import { configSaga, configReducer } from './common/config';
import { propagandaSaga, propagandaReducer } from './common/propagandas';
import { financiamentoSaga, financiamentoReducer } from './common/financiamento';

export default function* rootSaga() {
  yield all([
    LayoutSaga(),
    ...regulamentoSaga,
    ...recadoSaga,
    ...anuncioSaga,
    ...configSaga,
    ...propagandaSaga,
    ...financiamentoSaga
  ])
}

export const rootReducer = {
  regulamentoReducer,
  recadoReducer,
  anuncioReducer,
  configReducer,
  propagandaReducer,
  financiamentoReducer
}

export * from './common/regulamento';
export * from './common/recados';
export * from './common/anuncios';
export * from './common/config';
export * from './common/propagandas';
export * from './common/financiamento';
