import { MIXPANEL_TOKEN } from '@commons/Const';
import mixpanel from 'mixpanel-browser';

mixpanel.init(MIXPANEL_TOKEN, {
    // debug: true, // Debug Mode allows you to see the requests being sent by your implementation to Mixpanel, in real time, from your browser's developer console.
    track_pageview: true, // Registra automaticamente um evento de visualização de página sempre que a página é carregada.
    persistence: 'localStorage',
    // ignore_dnt: true  // To ignore the "Do Not Track" browser setting
});

const trackEvent = (eventName, properties = {}) => {
    if (Object.keys(properties).length > 0) {
        mixpanel.track(eventName, properties);
    } else {
        mixpanel.track(eventName);
    }
};

export default mixpanel;
export { mixpanel, trackEvent };