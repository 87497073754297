import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

class CardRecado extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <Card className="col-sm-12 col-lg-4 col-md-4">
                    <Link to={`recado/${this.props.item.id}`} className="">
                        <div className="containerImage">
                        <img
                            className="cardImage"
                            src={`https://appgadobom.com.br${this.props.item.fotoCapa}`}
                            alt={`${this.props.item.titulo}`}
                        />
                        </div>
                        <CardBody>
                            <h4 className="card-title mt-0">{this.props.item.titulo}</h4>
                        </CardBody>
                    </Link>
                </Card>
            </React.Fragment>
        );
    }
}

export default CardRecado;
