import { takeLatest, put, call } from 'redux-saga/effects';

import { findRecado } from '../service';
import {
  FIND_RECADO,
  FIND_RECADO_PENDING,
  FIND_RECADO_SUCCESS,
  FIND_RECADO_FAILURE,
} from '../actionTypes';

import { AlertError } from '@components/common/AlertToast'

function* sagaFindRecado(action) {
  yield put({ type: FIND_RECADO_PENDING, id: action.id})

  try {
    const recado = yield call(findRecado, action.args, action.id);

    yield put({ type: FIND_RECADO_SUCCESS, recado: recado })

  } catch (error) {
    yield put({ type: FIND_RECADO_FAILURE })

    AlertError('Falha ao carregar o recado. Tente novamente...');
  }
}

export default function* watchFindRecado() {
  yield takeLatest(FIND_RECADO, sagaFindRecado)
}
