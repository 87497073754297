import watchFindAnuncio from './saga/find';
import watchListAnuncios from './saga/listTable';
import watchFindGrupoAnuncio from './saga/findGrupo';
import watchFindSubGrupoAnuncio from './saga/findSubgrupo';

export const anuncioSaga = [
    watchFindAnuncio(),
    watchListAnuncios(),
    watchFindGrupoAnuncio(),
    watchFindSubGrupoAnuncio(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
