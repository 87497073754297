import React from "react";

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

class PoliticaPrivacidade extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Política de Privacidade</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/#">Gado Bom</Link></li>
                                    <li className="breadcrumb-item active">Política de Privacidade</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col">
                                        <div>
                                            <h2>Política de Privacidade</h2>
                                            <p><strong>Data de Última Atualização:</strong> 05/01/2024</p>

                                            <p>
                                                Agradecemos por utilizar App Gado Bom! Nós valorizamos a sua privacidade e queremos assegurar que você compreenda como suas informações são coletadas, utilizadas e protegidas. Por favor, leia atentamente esta Política de Privacidade.
                                            </p>

                                            <h3>1. Informações Coletadas:</h3>
                                            <ul>
                                                <li>Ao utilizar o aplicativo App Gado Bom, não é necessário criar uma conta para acessar as funcionalidades principais.</li>
                                                <li>Caso opte por fornecer informações como nome, e-mail, telefone ou localização, saiba que esses dados não são obrigatórios e serão usados exclusivamente para aprimorar sua experiência no aplicativo.</li>
                                            </ul>

                                            <h3>2. Uso de Informações:</h3>
                                            <ul>
                                                <li>As informações fornecidas são utilizadas apenas para a funcionalidade específica do aplicativo e para enviar propostas de interesse que possam ser relevantes para você.</li>
                                                <li>Garantimos que seus dados são tratados com confidencialidade e não são compartilhados com terceiros sem o seu consentimento expresso.</li>
                                            </ul>

                                            <h3>3. Segurança:</h3>
                                            <ul>
                                                <li>Utilizamos protocolo seguro (HTTPS) para garantir que suas informações sejam transmitidas de maneira segura durante a utilização do aplicativo.</li>
                                                <li>Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição.</li>
                                            </ul>

                                            <h3>4. Consentimento:</h3>
                                            <p>Ao utilizar o aplicativo, você consente com a coleta e o uso das informações de acordo com esta Política de Privacidade.</p>

                                            <h3>5. Direitos do Usuário:</h3>
                                            <p>
                                                Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento.
                                                Para exercer esses direitos, entre em contato conosco através dos canais fornecidos no aplicativo ou envie um e-mail para
                                                {" "}<a href="mailto:oesterural@oesterural.com.br">oesterural@oesterural.com.br</a>.
                                            </p>

                                            <h3>6. Alterações nesta Política:</h3>
                                            <p>Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente. A data de última atualização será modificada para refletir as alterações.</p>

                                            <p>Agradecemos por confiar em nós. Se tiver dúvidas ou preocupações relacionadas à sua privacidade, entre em contato conosco através dos canais disponíveis no aplicativo.</p>

                                            <p>Obrigado por escolher App Gado Bom!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export function mapStateToProps(state) {
    const { } = state.entities;
    return {};
};

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PoliticaPrivacidade))
