import {
    FIND_PROPAGANDAS_PENDING,
    FIND_PROPAGANDAS_SUCCESS,
    FIND_PROPAGANDAS_FAILURE,
} from './actionTypes';


const initialState = { loading: false }

function propagandaReducer(state = initialState, action) {

    switch (action.type) {
        case FIND_PROPAGANDAS_PENDING:
            return {
                ...state,
                loading: true,
            }
         // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PROPAGANDAS_SUCCESS:
            return {
                ...state,
                propagandas: action.propagandas,
                loading: false
            }
        case FIND_PROPAGANDAS_FAILURE:
            return {
                ...state,
                propagandas: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { propagandaReducer };