import { formatNumber } from "@components/common/format";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";
import logolightImg from "../../../assets/images/logo-light.png";

class CardAnuncio extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.mountAnimalInfo = this.mountAnimalInfo.bind(this);
        this.mountPropRuralInfo = this.mountPropRuralInfo.bind(this);
        this.mountMaqAgricolaInfo = this.mountMaqAgricolaInfo.bind(this);
        this.mountImovelUrbanoInfo = this.mountImovelUrbanoInfo.bind(this);
        this.handleInfoTipo = this.handleInfoTipo.bind(this);
        this.getTipo = this.getTipo.bind(this);
    }

    mountAnimalInfo(item) {
        return (
            <>
                <Row className="cardText spaceBetween">
                    <p>{item.quantidade === "" || item.quantidade === null
                        ? '' : `Qtde.: ${item.quantidade}`}</p>
                    <p>{item.pesoAproximado === "" || item.pesoAproximado === null
                        ? '' : `Peso Aprox.: ${formatNumber(item.pesoAproximado ?? 0, 2)} kg`}</p>
                </Row>
                <Row className="spaceBetween anuncioValor">
                    <p>{item.valorKg === "" || item.valorKg === null
                        ? '' : `R$: ${formatNumber(item.valorKg ?? 0, 2)}/kg`}</p>
                    <p>{item.valor === "" || item.valor === null
                        ? '' : `R$: ${formatNumber(item.valor ?? 0, 2)}/unid.`}</p>
                </Row>
            </>
        )
    }

    mountPropRuralInfo(item) {
        return (
            <>
                <Row className="cardText spaceBetween">
                    <p>{item.areaTotal === "" || item.areaTotal === null
                        ? '' : `Área: ${formatNumber(item.areaTotal ?? 0, 2)}/ha`}</p>
                    <p>{item.valor === "" || item.valor === null
                        ? '' : `R$: ${formatNumber(item.valor ?? 0, 2)}/ha`}</p>
                </Row>
            </>
        )
    }


    mountImovelUrbanoInfo(item) {
        return (
            <>
                <Row className="cardText spaceBetween">
                    <p>{item.areaTotal === "" || item.areaTotal === null
                        ? '' : `Área: ${item.areaTotal}`}</p>
                    <p>{item.valor === "" || item.valor === null
                        ? '' : `R$: ${formatNumber(item.valor ?? 0, 2)}`}</p>
                </Row>
            </>
        )
    }

    mountMaqAgricolaInfo(item) {
        return (
            <>
                <Row className="spaceBetween anuncioValor">
                    <p>{item.quantidade === "" || item.quantidade === null
                        ? '' : `Qtde.: ${item.quantidade}`}</p>
                    <p>{item.valor === "" || item.valor === null
                        ? '' : `R$: ${formatNumber(item.valor ?? 0, 2)}/unid.`}</p>
                </Row>
            </>
        )
    }

    handleInfoTipo(tipo, item) {
        switch (tipo) {
            case "animais":
                return this.mountAnimalInfo(item);
            case "propriedades":
                return this.mountPropRuralInfo(item);
            case "maquinarios":
                return this.mountMaqAgricolaInfo(item);
            case "imovelUrbano":
                return this.mountImovelUrbanoInfo(item);
            default:
                return '';
        }
    }

    getTipo = (titulo) => {
        switch (titulo) {
            case "animais":
                return "animal";
            case "propriedades":
                return "propriedade";
            case "maquinarios":
                return "maquinario";
            case "imovelUrbano":
                return "imovel";
            default:
                return '';
        }
    }

    renderCidade(item) {
        try {
            if (item.pais.codigo === 1058) {
                if (item.cidade !== null && item.cidade !== undefined) {
                    return `${item.cidade.nome}/${item.cidade.estado.sigla}`;
                }
            } else {
                if (item.cidadeExterior === undefined) {
                    return `${item.pais.nome}`;
                } else {
                    if (item.cidadeExterior !== null) {
                        return `${item.cidadeExterior} - ${item.pais.nome}`;
                    }
                }
            }
            return 'Não informada';
        } catch (err) {
            return 'Não informada';
        }
    }

    render() {
        const item = this.props.item;
        const tipo = this.getTipo(this.props.tipo);

        return (
            <React.Fragment>
                <Card className="col-sm-12 col-lg-4 col-md-4">
                    <Link to={`${tipo}/${item.id}`} className="" tipo={this.props.tipo}>
                        <div className="containerImage">
                            <img src={item.fotoCapa === undefined || item.fotoCapa === null || item.fotoCapa === '' ?
                                `https://appgadobom.com.br/app/default/sem-foto.jpg`
                                : `https://appgadobom.com.br${item.fotoCapa}`}
                                className="cardImage"
                                alt={`${item.titulo}`}
                            />
                            {item.fotoCapa === undefined || item.fotoCapa === null || item.fotoCapa === '' ?
                                <React.Fragment />
                                : <img src={logolightImg} className="cardImageCaption" alt={`Logo Gado-Bom`} />
                            }
                        </div>
                        <CardBody>
                            <h4 className="card-title mt-0">{item.titulo}</h4>
                            {
                                item.financiavel || item.negociacao ?
                                    <center>
                                        {item.financiavel ? <span className="badge badge-success" style={{ marginRight: '5px' }}>Financiável</span> : <React.Fragment />}
                                        {item.negociacao ? <span className="badge badge-danger">Em negociação</span> : <React.Fragment />}
                                    </center>
                                    : <React.Fragment />
                            }
                            <div className="cardContent">
                                <Row className="cardText spaceBetween">
                                    <p>{item.lote === "" || item.lote === null
                                        ? '' : `Lote: ${item.lote}`}</p>
                                    <p>{item.cidade === "" || item.cidade === null
                                        ? '' : `Cidade: ${this.renderCidade(item)}`}</p>
                                </Row>
                                {item.parceiroExterno !== null && item.parceiroExterno !== undefined ?
                                    <Row className="cardText spaceBetween">
                                        <strong>Parceiro: {item.parceiroExterno.nome}</strong>
                                    </Row> : <React.Fragment />
                                }
                                {this.handleInfoTipo(this.props.tipo, item)}
                            </div>
                        </CardBody>
                    </Link>
                </Card>
            </React.Fragment>
        );
    }
}

export default CardAnuncio;
