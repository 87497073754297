import React, { Component } from "react";

import { Modal } from 'reactstrap';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import logolightImg from "../../../assets/images/logo-light.png";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { WS_URL_SITE } from '@commons/Const';
import './style.css'

export default class LightBox extends Component {

    constructor(props) {
        super(props);
        this.state = {

            activeIndex: 0,
            photoIndex: 0,
            isGalleryZoom: false,
            slideLenght: 0,
            isOpen: true,
        };

        this.testte = this.testte.bind(this);
        this.formatImage = this.formatImage.bind(this);
        this.formatVideo = this.formatVideo.bind(this);

        this.renderCarrossel = this.renderCarrossel.bind(this);
        this.renderImage = this.renderImage.bind(this);
        this.renderVideo = this.renderVideo.bind(this);
        this.next = this.next.bind(this);
        this.previous = this. previous.bind(this);
    }

    testte = () => {
        let state = this.state;
        this.state.isOpen = false;
        this.setState(state);
        // this.callbackFunction;
    };

    formatImage(imagens){
        let formatedList = [];

        imagens.map((item, index) => {
            formatedList = [...formatedList, {
                url: `${WS_URL_SITE}${item.foto.path}`,
                type: "photo",
                altTag: ""
            }];
        });

        return formatedList;
    }

    formatVideo(videos){
        let formatedList = [];

        videos.map((item, index) => {
            formatedList = [...formatedList, {
                url: `https://www.youtube.com/embed/${item}?rel=0&showinfo=0&allowFullScreen=true`,
                type: "video",
                altTag: ""
            }];
        });

        console.log("FORMATED " + formatedList);
        return formatedList;
    }


// ++++++++

    next= (items) => {
        // if (this.animating) return
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }
    
    previous= (items) => {
        // if (this.animating) return
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex })
    }

    renderCarrossel(galeria, videos, next, previous){
        
        const { activeIndex } = this.state;
        const images = this.renderImage(galeria);
        const slideVideos = this.renderVideo(videos);
        const slides = [...images, ...slideVideos];

        return <React.Fragment>
            <AliceCarousel
                activeIndex={activeIndex}
                mouseTracking
                disableDotsControls
                disableButtonsControls
                autoHeight
                items={slides}
            />
             <div className={"divControles mt-1 mb-2"}>
                <button className={"btn btn-light"} onClick={() => next}>Anterior</button>
                <nav className={"numberSlide"}>{`${activeIndex+1} | ${slides.length}`}</nav>
                <button className={"btn btn-light"} onClick={() => previous}>Próximo</button>
            </div>
        </React.Fragment>
    }

    renderImage(galeria) {
        const { photoIndex } = this.state;
        return galeria.map((item, index) => {
            return (
                <div className="containerLB">
                    <img
                        src={`${WS_URL_SITE}${item.foto.path}`}
                        className="lbSlideFrame"
                        alt={"Imagem"}
                        onClick={() =>
                            this.setState({ isGalleryZoom: true, photoIndex: index })
                        }
                    />
                    <img src={logolightImg} className="lbCaption" alt={`Logo Gado-Bom`}/>
                </div>
            );
        });
    }

    renderVideo(videos) {
        return videos.map((item, index) => {
            return (
                <iframe
                    allow="fullscreen;"
                    allowFullScreen="allowFullScreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    frameBorder="0"
                    title="Video"
                    className="slideFrame"
                    src={`https://www.youtube.com/embed/${item}?rel=0&showinfo=0&allowfullscreen=true`}
                ></iframe>
            );
        });
    }


// ++++++++++++



    render() {
        const { isOpen, activeIndex, imagens, videos, handleClose, next, previous } = this.props;
        const itens = [...this.formatImage(imagens), ...this.formatVideo(videos)];

        return (
            <React.Fragment> 
                <Modal isOpen={isOpen} size="xl" className={"lbModal"}>
                    <div className="containerLB">
                        <ReactImageVideoLightbox
                            className={"lbSlider"}
                            data={itens}
                            startIndex={activeIndex}
                            showResourceCount={true}
                            onCloseCallback={handleClose}
                            onNavigationCallback={(currentIndex) =>
                                console.log(`Current index: ${currentIndex}`)
                            }
                        />
                        <img src={logolightImg} className="lbCaption" alt={`Logo Gado-Bom`}/>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
